import React from "react";
import PropTypes from "prop-types";
import { CHALLENGE_PROP_TYPES, GROUP_SCORE_PROP_TYPES } from "./constants/prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LocalizedDate from "./localized-date";
import Paragraphs from "./paragraphs";
import Group from "./group";
import SubmissionsList from "./submissions-list";
import MediaViewer from "./media-viewer";
import { placeClass } from "./utils/classes";

const PortfolioChallengePreview = (props) => {
  const { challenge, toggle, defaultMedia, groupScores } = props;

  if (!challenge) return null;

  return (
    <Modal
      isOpen={!!challenge}
      toggle={() => toggle(null)}
      fade={false}
      size="xl"
      centered
      scrollable
    >
      <ModalHeader toggle={() => toggle(null)}>{challenge.name}</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col">
            <table className="table label-value">
              <tbody>
                <tr>
                  <th>Challenge #:</th>
                  <td>{challenge.number}</td>
                </tr>
                <tr>
                  <th>Leader:</th>
                  <td>{challenge.creator.fullName}</td>
                </tr>
                <tr>
                  <th>Dates:</th>
                  <td>
                    <LocalizedDate value={challenge.startDate} /> to{" "}
                    <LocalizedDate value={challenge.endDate} />{" "}
                    <span className="challenge-status">
                      ({challenge.state})
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col mt-2">
            {!challenge.public && (
              <i
                className="private-indicator fas fa-lock"
                title="This is a private challenge."
              />
            )}
            This is a {challenge.public ? "public" : " private"} challenge.
          </div>
        </div>
        {challenge.state === "Pending" && (
          <div className="mt-2 alert alert-warning">
            This challenge has not yet started.
          </div>
        )}
        {challenge.state === "Complete" && (
          <div className="mt-2 alert alert-warning">
            This challenge has completed.
          </div>
        )}
        {challenge.submissions.length && (
          <div className="row mt-2">
            <div className="col">
              <a href="#challenge-submissions">
                See all {challenge.submissions.length} submissions.
              </a>
            </div>
          </div>
        )}
        <h3>The Challenge</h3>
        <div className="row">
          <div className="col">
            <MediaViewer
              mediaType={challenge.challengeMediaType}
              media={challenge.challengeMedia}
              alt={`Challenge ${challenge.challengeMediaType}`}
              defaultMedia={defaultMedia}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Paragraphs paragraphs={challenge.description} />
          </div>
        </div>
        <h3>The Prize</h3>
        <div className="row">
          <div className="col">
            <MediaViewer
              mediaType={challenge.prizeMediaType}
              media={challenge.prizeMedia}
              alt={`Prize ${challenge.challengeMediaType}`}
              defaultMedia={defaultMedia}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Paragraphs paragraphs={challenge.prize} />
          </div>
        </div>
        <h3>The Competing Flocks</h3>
        <div className="card-deck">
          {groupScores.map((groupScore) => (
            <Group
              key={`group-${groupScore.group.id}`}
              group={groupScore.group}
              suppressLinks
              className={placeClass(groupScore.placement)}
              defaultMedia={defaultMedia}
            >
            {{
              headerContent:
                challenge.challengeState !== "PENDING" ? (
                  <div className="group-score-header d-flex flex-row align-items-center flex-grow-1 justify-content-between">
                    <div className="mr-2">{groupScore.score} Total</div>

                    {groupScore.placement && (
                      <div className="d-flex flex-row align-items-center group-award-indicator">
                        <div className="group-award">
                          <i className="fas fa-award" />
                        </div>
                        {groupScore.placementOrdinal}
                      </div>
                    )}
                  </div>
                ) : null,
            }}
            </Group>
          ))}
        </div>
        <h3 id="challenge-submissions">The Submissions</h3>
        <SubmissionsList submissions={challenge.submissions} suppressLinks defaultMedia={defaultMedia} />
      </ModalBody>
    </Modal>
  );
};

PortfolioChallengePreview.propTypes = {
  challenge: PropTypes.exact(CHALLENGE_PROP_TYPES),
  toggle: PropTypes.func.isRequired,
  groupScores: PropTypes.arrayOf(PropTypes.exact(GROUP_SCORE_PROP_TYPES)),
};

PortfolioChallengePreview.defaultProps = {
  challenge: null,
};

export default PortfolioChallengePreview;
