import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

const ToggleSwitch = (props) => {
  const { id, name, checked, onLabel, offLabel, onChange } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className="d-flex">
      <Switch
        id={id}
        className="switch"
        checked={isChecked}
        onChange={(checked) => {
          setIsChecked(checked);
          onChange(checked);
        }}
      />
      {isChecked ? onLabel : offLabel}
      <input type="hidden" name={name} value={isChecked ? 1 : 0} />
    </div>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
ToggleSwitch.defaultProps = {
  onChange: () => {},
};

export default ToggleSwitch;
