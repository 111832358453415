import PropTypes from "prop-types";

export const MEDIA_PROP_TYPES = {
  ":original": PropTypes.arrayOf(
    PropTypes.shape({ ssl_url: PropTypes.string })
  ),
  thumbnail: PropTypes.arrayOf(PropTypes.shape({ ssl_url: PropTypes.string })),
  largeImage: PropTypes.arrayOf(PropTypes.shape({ ssl_url: PropTypes.string })),
  mediumImage: PropTypes.arrayOf(
    PropTypes.shape({ ssl_url: PropTypes.string })
  ),
  plain720Vp9Encoded: PropTypes.arrayOf(
    PropTypes.shape({ ssl_url: PropTypes.string })
  ),
  plain720H264Encoded: PropTypes.arrayOf(
    PropTypes.shape({ ssl_url: PropTypes.string })
  ),
  videoPoster: PropTypes.arrayOf(
    PropTypes.shape({ ssl_url: PropTypes.string })
  ),
};

export const USER_PROP_TYPES = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  avatarMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  avatarMediaType: PropTypes.string,
  initials: PropTypes.string,
};

export const GROUP_USER_PROP_TYPES = {
  ...USER_PROP_TYPES,
  canRemoveFromGroup: PropTypes.bool,
  groupGroupUserPath: PropTypes.string,
};

export const GROUP_PROP_TYPES = {
  id: PropTypes.number.isRequired,
  abbreviatedDescription: PropTypes.string.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.exact(GROUP_USER_PROP_TYPES)),
  creator: PropTypes.exact(USER_PROP_TYPES),
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  editPath: PropTypes.string.isRequired,
  destroyPath: PropTypes.string.isRequired,
  public: PropTypes.bool.isRequired,
  privacyLevel: PropTypes.oneOf([
    "do_not_hide_member_details",
    "hide_member_details_from_non_members",
    "hide_member_details_from_everyone",
  ]).isRequired,
  isGroupMember: PropTypes.bool.isRequired,
  isGroupCreator: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDestroy: PropTypes.bool.isRequired,
  number: PropTypes.string,
  groupMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  groupMediaType: PropTypes.string,
};

export const SUBMISSION_PROP_TYPES = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  submissionMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  submissionMediaType: PropTypes.string,
  user: PropTypes.exact({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    email: PropTypes.string,
    avatarMedia: PropTypes.shape(MEDIA_PROP_TYPES),
    avatarMediaType: PropTypes.string,
  }).isRequired,
  group: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    public: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    groupMedia: PropTypes.shape(MEDIA_PROP_TYPES),
    groupMediaType: PropTypes.string,
    userIsInGroup: PropTypes.bool.isRequired,
    userManagesGroup: PropTypes.bool.isRequired,
  }).isRequired,
  challenge: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  belongsToCurrentUser: PropTypes.bool.isRequired,
  canDestroy: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
};

export const CHALLENGE_PROP_TYPES = {
  id: PropTypes.number,
  abbreviatedDescription: PropTypes.string,
  creator: PropTypes.exact(USER_PROP_TYPES),
  description: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.string,
  path: PropTypes.string,
  editPath: PropTypes.string,
  destroyPath: PropTypes.string,
  public: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  state: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDestroy: PropTypes.bool.isRequired,
  isChallengeCreator: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  submissions: PropTypes.arrayOf(PropTypes.exact(SUBMISSION_PROP_TYPES)),
  prize: PropTypes.string,
  usingDefaultPrizeImage: PropTypes.bool.isRequired,
  allowJoinsAfterStart: PropTypes.bool.isRequired,
  sendCertificates: PropTypes.bool.isRequired,
  challengeMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  challengeMediaType: PropTypes.string,
  prizeMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  prizeMediaType: PropTypes.string,
};

export const GROUP_SCORE_PROP_TYPES = {
  group: PropTypes.exact(GROUP_PROP_TYPES).isRequired,
  placement: PropTypes.number,
  placementOrdinal: PropTypes.string,
  score: PropTypes.number,
  canDestroy: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.exact(USER_PROP_TYPES)),
  userHasAffinity: PropTypes.bool.isRequired,
  challengeGroupUserAffinityId: PropTypes.number,
  challengeId: PropTypes.number,
};

export const PORTFOLIO_PROP_TYPES = {
  id: PropTypes.number,
  user: PropTypes.exact(USER_PROP_TYPES).isRequired,
  university: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  published: PropTypes.bool.isRequired,
  description: PropTypes.string,
  path: PropTypes.string,
  editPath: PropTypes.string,
  portfolioMedia: PropTypes.shape(MEDIA_PROP_TYPES),
  portfolioMediaType: PropTypes.string,
  challenges: PropTypes.arrayOf(PropTypes.exact(CHALLENGE_PROP_TYPES)),
  groups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)),
  submissions: PropTypes.arrayOf(PropTypes.exact(SUBMISSION_PROP_TYPES)),
  checkoutStartPath: PropTypes.string,
  destroyPath: PropTypes.string,
  publicUrl: PropTypes.string,
  isCreator: PropTypes.bool,
  challengeGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact(GROUP_SCORE_PROP_TYPES))),
};
