import React from "react";
import PropTypes from "prop-types";
import { render } from "react-dom";

const MediaViewer = (props) => {
  const { mediaType, media, purpose, alt, className, defaultMedia } = props;

  const renderForPurpose = () => {
    switch (purpose) {
      case "thumbnail":
        return (
          <img
            src={(media?.thumbnail || defaultMedia.thumbnail)[0].sslUrl}
            className="media-preview"
            alt={alt || mediaType || 'image'}
          />
        );
      case "preview":
        return (
          <img
            src={(media?.mediumImage || media?.videoPoster || defaultMedia.mediumImage)[0].sslUrl}
            className="media-preview"
            alt={alt || mediaType || 'image'}
          />
        );
      case "show":
        return (
          <>
            {(mediaType === "image" || (mediaType === "video" && !media?.videoPoster)) && (
              <img
                src={(media?.largeImage || defaultMedia.largeImage)[0].sslUrl}
                className="media-preview"
                alt={alt || mediaType || 'image'}
              />
            )}
            {(mediaType === "video" && media?.videoPoster) && (
              <video
                id="player"
                className="media-preview"
                playsInline
                controls
                poster={media.videoPoster[0].sslUrl}
              >
                <source
                  src={media.plain720H264Encoded[0].sslUrl}
                  type={media.plain720H264Encoded[0].mime}
                />
                <source
                  src={media.plain720Vp9Encoded[0].sslUrl}
                  type={media.plain720Vp9Encoded[0].mime}
                />
              </video>
            )}
          </>
        );
    }
  };

  return <div className={className}>{renderForPurpose()}</div>;
};

MediaViewer.propTypes = {
  mediaType: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
  purpose: PropTypes.oneOf(["show", "preview", "thumbnail"]),
  alt: PropTypes.string,
  className: PropTypes.string,
  defaultMedia: PropTypes.object.isRequired
};

MediaViewer.defaultProps = {
  purpose: "show",
  alt: null,
  className: null,
};

export default MediaViewer;
