import React, { useState } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "./toggle-switch";

const FlockVisibilitySettings = (props) => {
  const [isPublic, setIsPublic] = useState(props.isPublic);
  const [privacyLevel, setPrivacyLevel] = useState(props.privacyLevel);

  return (
    <>
      <div className="form-group">
        <div className="d-flex">
          <label htmlFor="group_public" className="form-check-label">
            Is this a public flock?
          </label>
          <div className="toggle-switch">
            <ToggleSwitch
              id="group_public"
              name="group[public]"
              checked={isPublic}
              onChange={(checked) => setIsPublic(checked)}
              onLabel="Public"
              offLabel="Private"
            />
          </div>
        </div>
        <small className="form-text text-muted">
          As a reminder, anyone can join a public flock.
        </small>
      </div>

      {!isPublic && (
        <div className="form-group">
          <label>
            Who is permitted to see names and profile images of this flock's
            members?
          </label>
          <div className="form-check m-2">
            <input
              className="form-check-input"
              type="radio"
              name="group[privacy_level]"
              id="group_privacy_level_do_not_hide"
              value="do_not_hide_member_details"
              onChange={() => {
                setPrivacyLevel("do_not_hide_member_details");
              }}
              checked={privacyLevel === "do_not_hide_member_details"}
            />
            <label
              className="form-check-label"
              htmlFor="group_privacy_level_do_not_hide"
            >
              Anyone
            </label>
          </div>
          <div className="form-check m-2">
            <input
              className="form-check-input"
              type="radio"
              name="group[privacy_level]"
              id="group_privacy_level_hide_from_non_members"
              value="hide_member_details_from_non_members"
              onChange={() => {
                setPrivacyLevel("hide_member_details_from_non_members");
              }}
              checked={privacyLevel === "hide_member_details_from_non_members"}
            />
            <label
              className="form-check-label"
              htmlFor="group_privacy_level_hide_from_non_members"
            >
              Only members of this flock
            </label>
          </div>
          <div className="form-check m-2">
            <input
              className="form-check-input"
              type="radio"
              name="group[privacy_level]"
              id="group_privacy_level_hide_from_everyone"
              value="hide_member_details_from_everyone"
              onChange={() => {
                setPrivacyLevel("hide_member_details_from_everyone");
              }}
              checked={privacyLevel === "hide_member_details_from_everyone"}
            />
            <label
              className="form-check-label"
              htmlFor="group_privacy_level_hide_from_everyone"
            >
              Only you
            </label>
          </div>
          <small className="form-text text-muted">
            Your name and profile image is always visible.
          </small>
        </div>
      )}
    </>
  );
};

FlockVisibilitySettings.propTypes = {
  isPublic: PropTypes.bool.isRequired,
  privacyLevel: PropTypes.string.isRequired,
};

FlockVisibilitySettings.defaultProps = {};

export default FlockVisibilitySettings;
