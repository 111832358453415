import React from "react";
import PropTypes from "prop-types";
import { GROUP_PROP_TYPES } from "./constants/prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LocalizedDate from "./localized-date";
import Paragraphs from "./paragraphs";
import MediaViewer from "./media-viewer";

const PortfolioGroupPreview = (props) => {
  const { group, toggle, defaultMedia } = props;

  if (!group) return null;

  return (
    <Modal
      isOpen={!!group}
      toggle={() => toggle(null)}
      fade={false}
      size="xl"
      centered
      scrollable
    >
      <ModalHeader toggle={() => toggle(null)}>{group.name}</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col">
            <table className="table label-value">
              <tbody>
                <tr>
                  <th>Flock #:</th>
                  <td>{group.number}</td>
                </tr>
                <tr>
                  <th>Leader:</th>
                  <td>{group.creator.fullName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col mt-2">
            {!group.public && (
              <i
                className="private-indicator fas fa-lock"
                title="This is a private flock."
              />
            )}
            This is a {group.public ? "public" : " private"} flock.
          </div>
        </div>

        <div className="row">
          <div className="col">
            <MediaViewer
              mediaType={group.groupMediaType}
              media={group.groupMedia}
              alt={`Group ${group.groupMediaType}`}
              defaultMedia={defaultMedia}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Paragraphs paragraphs={group.description} />
          </div>
        </div>

        <h3>Flock Members</h3>
        <div className="card-deck">
          {group.allUsers.map((user) => (
            <div
              className="card mb-2 mt-2 p-3 user-card d-flex flex-row align-items-center"
              key={`user-${user.id}`}
            >
              <div
                className="user-icon mr-2"
                style={{
                  backgroundImage: `url('${user.avatarMedia?.thumbnail[0]?.sslUrl}')`,
                }}
              >
                {!user.avatarMedia && user.initials}
              </div>
              <div className="user-name">
                <div>{user.fullName}</div>
                {group.creator.id === user.id && (
                  <small>
                    <i className="fas fa-crown" /> Flock Leader
                  </small>
                )}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

PortfolioGroupPreview.propTypes = {
  group: PropTypes.exact(GROUP_PROP_TYPES),
  toggle: PropTypes.func.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

PortfolioGroupPreview.defaultProps = {
  group: null,
};

export default PortfolioGroupPreview;
