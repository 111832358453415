import React, { useState } from "react";
import PropTypes from "prop-types";
import { PORTFOLIO_PROP_TYPES } from "./constants/prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChallengeCard from "./challenge-card";
import Group from "./group";
import Submission from "./submission";
import ConfirmButton from "./confirm-button";
import PortfolioChallengePreview from "./portfolio-challenge-preview";
import Paragraphs from "./paragraphs";
import PortfolioGroupPreview from "./portfolio-group-preview";
import MediaViewer from "./media-viewer";

const Portfolio = (props) => {
  const {
    portfolio: {
      id,
      published,
      editPath,
      description,
      user: creator,
      university,
      challenges,
      groups,
      submissions,
      checkoutStartPath,
      destroyPath,
      isCreator,
      publicUrl,
      portfolioMedia,
      portfolioMediaType,
      challengeGroups
    },
    userPortfoliosPath,
    authenticityToken,
    defaultMedia,
  } = props;

  const [copied, setCopied] = useState({});
  const [previewChallenge, setPreviewChallenge] = useState(null);
  const [previewGroup, setPreviewGroup] = useState(null);
  const [previewGroupScores, setPreviewGroupScores] = useState(null);

  const copyLinkToClipboard = async (id, url) => {
    setCopied({ ...copied, [id]: true });
    await navigator.clipboard.writeText(url);

    setTimeout(() => {
      setCopied({ ...copied, [id]: null });
    }, 1000);
  };

  const createdChallenges = challenges.filter(
    (challenge) => challenge.creator.id === creator.id
  );
  const participatedInChallenges = challenges.filter(
    (challenge) => challenge.creator.id !== creator.id
  );
  const createdGroups = groups.filter(
    (group) => group.creator.id === creator.id
  );
  const joinedGroups = groups.filter(
    (group) => group.creator.id !== creator.id
  );

  return (
    <>
      <PortfolioChallengePreview
        challenge={previewChallenge}
        toggle={() => setPreviewChallenge(null)}
        defaultMedia={defaultMedia}
        groupScores={previewGroupScores}
      />
      <PortfolioGroupPreview
        group={previewGroup}
        toggle={() => setPreviewGroup(null)}
        defaultMedia={defaultMedia}
      />

      {isCreator && (
        <>
          <div className="alert alert-warning" role="alert">
            <p>
              You are viewing a preview of the portfolio you created for{" "}
              {university.name}.
            </p>
            {published ? (
              <>
                <p>
                  Please note that you are logged into Flockittome.com as
                  yourself and will see additional details and buttons that{" "}
                  {university.name} representatives will not. To see what the
                  portfolio will look like to other people, you may wish to log
                  out of Flockittome.com, use a different web browser, open an
                  incognito window before visiting the link, or send the link to
                  a friend to test it out.
                </p>
                <p>
                  This portfolio has been published and can be viewed by{" "}
                  {university.name} representatives at{" "}
                  <a href={publicUrl}>{publicUrl}</a>{" "}
                  <a
                    className="copy-link"
                    href="#"
                    onClick={() => copyLinkToClipboard(id, publicUrl)}
                    title="Copy Link"
                    aria-label="Copy Link"
                  >
                    {copied[id] === true ? (
                      <FontAwesomeIcon icon={"clipboard-check"} />
                    ) : (
                      <FontAwesomeIcon icon={["far", "clipboard"]} />
                    )}
                  </a>
                  .
                </p>
              </>
            ) : (
              <p>
                <strong>
                  This portfolio is not yet published and cannot only be seen by
                  you.
                </strong>{" "}
                To make this portfolio visible to admissions representatives at{" "}
                {university.name} you must first purchase and publish it.
              </p>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {published ? (
                <div className="badge badge-pill badge-success">Published</div>
              ) : (
                <div className="badge badge-pill badge-danger">Unpublished</div>
              )}
              {!published && (
                <a
                  type="button"
                  className="btn btn-primary vertical-icon-link"
                  href={checkoutStartPath}
                >
                  <FontAwesomeIcon icon={"share-square"} />
                  Purchase & Publish
                </a>
              )}
            </div>
            <div className="d-flex align-items-center">
              {!published && (
                <ConfirmButton
                  linkLabel="Delete"
                  linkIcon="ban"
                  confirmIcon="ban"
                  confirmLabel="Delete"
                  dialogTitle="Warning!"
                  dialogText={`Are you sure you want to delete this portfolio? This cannot be undone.`}
                  formId="destroy-portfolio"
                  orientation="vertical"
                  buttonClassName="btn btn-link p-0 text-danger"
                  formClassName="m-0 p-0 ml-auto"
                  method="delete"
                  action={destroyPath}
                  authenticityToken={authenticityToken}
                />
              )}

              <a
                href={userPortfoliosPath}
                className="btn btn-link vertical-icon-link"
              >
                <FontAwesomeIcon icon={"check"} />
                Done
              </a>

              <a href={editPath} className="btn btn-link vertical-icon-link">
                <FontAwesomeIcon icon={"edit"} />
                Edit
              </a>
            </div>
          </div>
        </>
      )}
      <h2>
        {creator.fullName} asks {university.name} admissions to review their
        volunteering leadership and efforts for consideration of being admitted.
        Thank you.
      </h2>
      <h3>Introduction</h3>
      <div className="media-viewer">
        <MediaViewer
          mediaType={portfolioMediaType}
          media={portfolioMedia}
          defaultMedia={defaultMedia}
        />
      </div>
      <div className="introduction">
        <Paragraphs paragraphs={description} />
      </div>

      {createdChallenges.length > 0 && (
        <>
          <h3>
            The following are volunteer programs I personally created, showing
            my values and leadership skills.
          </h3>
          <p>Please click on a challenge for more information.</p>
          <div className="card-deck">
            {createdChallenges.map((challenge) => (
              <ChallengeCard
                key={`challenge-${challenge.id}`}
                challenge={challenge}
                onLinkClick={() => {
                  setPreviewChallenge(challenge);
                  setPreviewGroupScores(
                    challengeGroups.find(groups => groups[0].challengeId === challenge.id)
                  );
                }}
                defaultMedia={defaultMedia}
              />
            ))}
          </div>
        </>
      )}

      {participatedInChallenges.length > 0 && (
        <>
          <h3>
            The following are volunteer programs I personally participated in
          </h3>
          <p>Please click on a challenge for more information.</p>
          <div className="card-deck">
            {participatedInChallenges.map((challenge) => (
              <ChallengeCard
                key={`challenge-${challenge.id}`}
                challenge={challenge}
                onLinkClick={() => {
                  setPreviewChallenge(challenge);
                  setPreviewGroupScores(
                    challengeGroups.find(groups => groups[0].challengeId === challenge.id)
                  );
                }}
                defaultMedia={defaultMedia}
              />
            ))}
          </div>
        </>
      )}

      {createdGroups.length > 0 && (
        <>
          <h3>
            The following are flocks I personally created, showing my ability to
            lead and inspire others to volunteer.
          </h3>
          <p>Please click on a flock for more information.</p>
          <div className="card-deck">
            {createdGroups.map((group) => (
              <Group
                key={`group-${group.id}`}
                group={group}
                onLinkClick={() => setPreviewGroup(group)}
                defaultMedia={defaultMedia}
              />
            ))}
          </div>
        </>
      )}

      {joinedGroups.length > 0 && (
        <>
          <h3>
            The following are flocks I joined to help do good in the world.
          </h3>
          <p>Please click on a flock for more information.</p>
          <div className="card-deck">
            {joinedGroups.map((group) => (
              <Group
                key={`group-${group.id}`}
                group={group}
                onLinkClick={() => setPreviewGroup(group)}
                defaultMedia={defaultMedia}
              />
            ))}
          </div>
        </>
      )}

      {submissions.length > 0 && (
        <>
          <h3>My own proofs of participation in volunteer opportunities</h3>
          <div className="card-deck">
            {submissions.map((submission) => (
              <Submission
                key={`submission-${submission.id}`}
                submission={submission}
                showChallengeLink
                suppressLinks
                defaultMedia={defaultMedia}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

Portfolio.propTypes = {
  portfolio: PropTypes.exact(PORTFOLIO_PROP_TYPES).isRequired,
  userPortfoliosPath: PropTypes.string,
  authenticityToken: PropTypes.string,
  defaultMedia: PropTypes.object.isRequired,
};

Portfolio.defaultProps = {
  userPortfoliosPath: null,
  authenticityToken: null,
};

export default Portfolio;
