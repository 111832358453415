import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { USER_PROP_TYPES } from "./constants/prop-types";
import { Spinner } from "reactstrap";
import axios from "axios";
import ChallengeCard from "./challenge-card";
import Group from "./group";
import OffensiveContentReportModal from "./offensive-content-report-modal";

const LandingPage = (props) => {
  const { currentUser, challengesPath, searchesPath, defaultMedia, authenticityToken, reportContentPath } = props;

  const [challenges, setChallenges] = useState([]);
  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState(0);
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchSuggestion, setSearchSuggestion] = useState(null);
  const [searchRecords, setSearchRecords] = useState([]);
  const [searchPage, setSearchPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("recent");
  const [showOffensiveContentReportDialog, setShowOffensiveContentReportDialog] = useState(false);
  const [reportedContent, setReportedContent] = useState(null);

  const buildSortUrl = (sort) =>
  `${challengesPath}?sort=${sort}`;

  useEffect(() => {
    setLoading(true);
    setChallenges([]);
    axios
      .get(buildSortUrl(sort), {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json",
        },
      })
      .then((response) => {
        setChallenges(response.data);
        setLoading(false);
      });
  }, [sort]);

  useEffect(() => {
    resetSearch();
    clearTimeout(timer);
    if (query) {
      setTimer(
        setTimeout(() => {
          search();
          setTimer(0);
        }, 250)
      );
    }
  }, [query]);

  const buildSearchUrl = (page) =>
    `${searchesPath}?query=${query}&page=${page}`;

  const search = (page) => {
    page = page ? page : 1;
    setSearchPage(page);

    setLoading(true);
    axios
      .get(buildSearchUrl(page), {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json",
        },
      })
      .then((response) => {
        setSearchSuggestion(response.data.suggestion);
        setSearchMatches(response.data.matches);
        setSearchRecords([...searchRecords, ...response.data.records]);
        setLoading(false);
      });
  };

  const resetSearch = () => {
    setSearchSuggestion(null);
    setSearchMatches(null);
    setSearchRecords([]);
  };

  const searchResultCard = (type, record) => {
    switch (type) {
      case "group":
        return <Group key={`${type}-${record.id}`} group={record} defaultMedia={defaultMedia} />;
      case "challenge":
        return <ChallengeCard key={`${type}-${record.id}`} challenge={record} defaultMedia={defaultMedia}>
                 {{ footerContent: footerLink(record) }}
               </ChallengeCard>;
    }
  };

  const footerLink = (challenge) =>
  (
    <>
      <div>
        <button
          className="btn btn-link mr-2"
          onClick={() => {
            setShowOffensiveContentReportDialog(true);
            setReportedContent(challenge);
          }}
        >
          <i className="fas fa-flag" />
          <span className="smaller">Report Offensive Content</span>
        </button>
      </div>
    </>
  );

  return (
    <>
      <div>
        <h2>Welcome, {currentUser.fullName}</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            resetSearch();
            search();
          }}
          className="search-form form-row"
        >
          <div className="col-12">
            <label className="sr-only" htmlFor="inlineFormInputGroup">
              Search
            </label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-search" />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Search for a challenge or flock!"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <small className="form-text text-muted">
              EG: Challenge #, Flock #, or text.
            </small>
          </div>
        </form>

        <div>
          {query ? (
            <div>
              <h3>Search Results</h3>
              {searchSuggestion ? (
                <button
                  className="btn btn-link"
                  onClick={() => setQuery(`${searchSuggestion}`)}
                >
                  Did you mean '{searchSuggestion}'?
                </button>
              ) : null}

              {searchRecords.length ? (
                <div className="card-deck">
                  {searchRecords.map(({ type, record: record }) =>
                    searchResultCard(type, record)
                  )}

                  {searchRecords.length < searchMatches ? (
                    <button
                      type="button"
                      className="btn card mb-3 mt-3 d-flex justify-content-center align-items-center"
                      onClick={() => search(searchPage + 1)}
                    >
                      <span>
                        <i className="far fa-search-plus" />
                        Show More Search Results
                      </span>
                    </button>
                  ) : null}
                </div>
              ) : (
                <div>
                  {!loading && (
                    <div className="d-flex justify-content-center mt-3">
                      <div>
                        <i className="far fa-frown" />
                        No challenges or flocks match the query.
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <h3>New Challenges</h3>
              {/* <button
                className={`btn btn-outline-secondary filter-btn ${sort === "recent" ? "active" : ""}`}
                id="recent-button"
                onClick={() => setSort("recent")}
              >
                New Challenges
              </button>
              <button
                className={`btn btn-outline-secondary ${sort === "popular" ? "active" : ""}`}
                id="popular-button"
                onClick={() => setSort("popular")}
              >
                Popular Challenges
              </button> */}
              <div className="card-deck">
                {challenges.map((challenge) => (
                  <ChallengeCard key={challenge.id} challenge={challenge} defaultMedia={defaultMedia}>
                    {{ footerContent: footerLink(challenge) }}
                  </ChallengeCard>
                ))}
              </div>
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center mt-3">
              <Spinner style={{ width: "3rem", height: "3rem" }} />
            </div>
          ) : null}
        </div>
      </div>
      
      <OffensiveContentReportModal 
        showModal={showOffensiveContentReportDialog}
        setShowModal={setShowOffensiveContentReportDialog}
        currentUser={currentUser}
        reportedContent={reportedContent}
        reportedContentType="Challenge"
        authenticityToken={authenticityToken}
        reportContentPath={reportContentPath}
      />
    </>
  );
};

LandingPage.propTypes = {
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  challengesPath: PropTypes.string.isRequired,
  searchesPath: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  reportContentPath: PropTypes.string.isRequired,
};

LandingPage.defaultProps = {};

export default LandingPage;
