import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const buildFormData = (form) => {
  return Array.from(form.elements).reduce((data, formElement) => {
    if (formElement.files && !formElement.files.length) return data;

    data.append(
      formElement.name || formElement.id,
      formElement.type === "file" ? formElement.files[0] : formElement.value
    );
    return data;
  }, new FormData());
};

const ValidatableForm = (props) => {
  const {
    action,
    method,
    id,
    authenticityToken,
    onSuccess,
    setSubmitting,
    onError,
    encType,
  } = props;

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      setSubmitting(true);
      const response = await axios[method](action, buildFormData(form), {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json",
        },
        validateStatus: (status) =>
          status === 200 || status === 201 || status === 422 || status === 403,
      });
      setSubmitting(false);

      if (response.status === 422 || response.status === 403) {
        form.classList.remove("was-validated");
        setErrors(response.data);
        onError(response.data);
      } else {
        onSuccess(response.data);
      }
    } else {
      form.classList.add("was-validated");
    }
  };

  const buildFormChildren = (children) => {
    if (!children) return children;
    if (typeof children === "string") return children;

    if (!children.props?.children) return children;

    return [children].flat().map((child, index) => {
      if (!child) return child;
      if (typeof child === "string") return child;
      if (Array.isArray(child)) return buildFormChildren(child);

      const { children: newChildren, ...newProps } = child.props;

      const errorKeys = Object.keys(errors);

      if (errorKeys.length) {
        if (errorKeys.includes(newProps.id)) {
          newProps.className = `${newProps.className} is-invalid`;
        } else {
          newProps.className = `${newProps.className} is-valid`;
        }
      }

      return React.cloneElement(
        child,
        { key: index, className: newProps.className },
        buildFormChildren(newChildren)
      );
    });
  };

  const children = useMemo(() => buildFormChildren(props?.children), [
    props?.children,
    errors,
  ]);

  return (
    <form
      action={action}
      id={id}
      noValidate
      onSubmit={handleSubmit}
      encType={encType}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />

      {children}
    </form>
  );
};

ValidatableForm.propTypes = {
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func,
  method: PropTypes.string,
  onError: PropTypes.func,
  encType: PropTypes.string,
};

ValidatableForm.defaultProps = {
  setSubmitting: () => {},
  method: "post",
  onError: () => {},
  encType: "application/x-www-form-urlencoded",
};

export default ValidatableForm;
