import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SUBMISSION_PROP_TYPES } from "./constants/prop-types";
import SubmissionDialog from "./submission-dialog";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import SubmissionsList from "./submissions-list";

const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
};

const ChallengeSubmissions = (props) => {
  const {
    challengeState,
    challengeStartDate,
    challengeEndDate,
    authenticityToken,
    challengeSubmissionsPath,
    challengeSubmissionPath,
    submissions,
    refreshChallenge,
    canSubmitToChallenge,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    defaultMedia,
    setShowOffensiveContentReportDialog,
    setReportedContent,
    setReportedContentType,
  } = props;

  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [
    showSubmissionSuccessfulDialog,
    setShowSubmissionSuccessfulDialog,
  ] = useState(false);
  const [liveChallengeState, setLiveChallengeState] = useState(challengeState);

  const addSubmission = (submission) => {
    setShowSubmissionDialog(false);
    refreshChallenge();
    setShowSubmissionSuccessfulDialog(true);
  };

  const deleteSubmission = async (submissionId) => {
    const response = await axios.delete(
      challengeSubmissionPath.replace(/:id/, submissionId),
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": authenticityToken,
          accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      refreshChallenge();
    } else {
      // TODO: actually handle an error
    }
  };

  const userHasSubmitted = useMemo(
    () => submissions.find((submission) => submission.belongsToCurrentUser),
    [submissions]
  );

  const updateChallengeState = () => {
    if (Date.parse(challengeEndDate) < Date.now()) {
      setLiveChallengeState("COMPLETE");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateChallengeState();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {userHasSubmitted ? (
        <div className="alert alert-primary user-has-submitted">
          🎉 Thank you for your amazing submission! 🎉
        </div>
      ) : null}

      {
        {
          PENDING: (
            <div className="alert alert-warning">
              This challenge has not started yet. Check back after{" "}
              {formatDate(challengeStartDate)}!
            </div>
          ),
          COMPLETE: (
            <div className="alert alert-success">
              This challenge is complete and the submissions have been frozen.
            </div>
          ),
        }[liveChallengeState]
      }

      {(canSubmitToChallenge && liveChallengeState === "ACTIVE") ? (
        <div className="row">
          <div className="col">
            <button
              className="btn btn-secondary mt-2 mb-2"
              onClick={() => setShowSubmissionDialog(true)}
            >
              <i className="fas fa-image" /> I did the challenge! (Submit Proof)
            </button>
          </div>
        </div>
      ) : null}

      {liveChallengeState !== "PENDING" ? (
        <SubmissionsList
          submissions={submissions}
          deleteSubmission={deleteSubmission}
          challengeIsActive={liveChallengeState === "ACTIVE"}
          defaultMedia={defaultMedia}
          setReportedContent={setReportedContent}
          setReportedContentType={setReportedContentType}
          setShowOffensiveContentReportDialog={setShowOffensiveContentReportDialog}
        />
      ) : null}

      <SubmissionDialog
        showModal={showSubmissionDialog}
        setShowModal={setShowSubmissionDialog}
        authenticityToken={authenticityToken}
        challengeSubmissionsPath={challengeSubmissionsPath}
        addSubmission={addSubmission}
        environment={environment}
        mediaUploadParams={mediaUploadParams}
        mediaUploadSignature={mediaUploadSignature}
        defaultMedia={defaultMedia}
      />

      <Modal
        isOpen={showSubmissionSuccessfulDialog}
        toggle={() => setShowSubmissionSuccessfulDialog(false)}
        fade={false}
        centered
      >
        <ModalHeader toggle={() => setShowSubmissionSuccessfulDialog(false)}>
          Submission Received!
        </ModalHeader>
        <ModalBody>
          Your submission has been posted to this challenge!
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowSubmissionSuccessfulDialog(false)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ChallengeSubmissions.propTypes = {
  challengeState: PropTypes.oneOf(["PENDING", "ACTIVE", "COMPLETE"]).isRequired,
  challengeStartDate: PropTypes.instanceOf(Date).isRequired,
  challengeEndDate: PropTypes.instanceOf(Date).isRequired,
  submissions: PropTypes.arrayOf(PropTypes.exact(SUBMISSION_PROP_TYPES))
    .isRequired,
  authenticityToken: PropTypes.string.isRequired,
  challengeSubmissionsPath: PropTypes.string.isRequired,
  challengeSubmissionPath: PropTypes.string.isRequired,
  refreshChallenge: PropTypes.func.isRequired,
  canSubmitToChallenge: PropTypes.bool.isRequired,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  setReportedContent: PropTypes.func.isRequired,
  setReportedContentType: PropTypes.func.isRequired,
  setShowOffensiveContentReportDialog: PropTypes.func.isRequired,
};

ChallengeSubmissions.defaultProps = {};

export default ChallengeSubmissions;
