import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalContent from "./modal-content";

const ModalDialogButton = (props) => {
  const { label, icon, className, spanClassName, url } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="modal-dialog-button-container">
      <button
        className={className}
        onClick={() => setShowModal(true)}
      >
        <i className={`fas fa-${icon}`} />
        <span className={spanClassName}>{label}</span>
      </button>
      <ModalContent
        url={url}
        toggle={() => setShowModal(false)}
        isOpen={showModal}
      />
    </div>
  );
};

ModalDialogButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  spanClassName: PropTypes.string,
  onShowDialog: PropTypes.func,
  onJsonResponse: PropTypes.func,
};
ModalDialogButton.defaultProps = {
  className: "btn btn-secondary",
  spanClassName: null,
  onShowDialog: null,
  onJsonResponse: null,
};

export default ModalDialogButton;
