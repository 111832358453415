import React from "react";
import PropTypes from "prop-types";
import { SUBMISSION_PROP_TYPES } from "./constants/prop-types";
import Submission from "./submission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const PortfolioSubmission = (props) => {
  const { submission, selected, onSelect, onDeselect, defaultMedia } = props;

  return (
    <Submission
      key={`submission-${submission.id}`}
      submission={submission}
      className={classNames("portfolio-item", {
        selected: selected,
      })}
      target="_blank"
      defaultMedia={defaultMedia}
    >
      {{
        beforeContent: selected ? (
          <div className="selected-item">
            <FontAwesomeIcon icon={["fas", "check-circle"]} size="3x" />
          </div>
        ) : null,
        footerContent: selected ? (
          <>
            <input
              type="hidden"
              name="portfolio[submission_ids][]"
              value={submission.id}
            />
            <button
              className="btn btn-link mr-2"
              type="button"
              onClick={onDeselect}
            >
              <FontAwesomeIcon icon={["fas", "minus"]} />
              Do Not Include this Submission
            </button>
          </>
        ) : (
          <button
            className="btn btn-link mr-2"
            type="button"
            onClick={onSelect}
          >
            <FontAwesomeIcon icon={["fas", "plus"]} />
            Include this Submission
          </button>
        ),
      }}
    </Submission>
  );
};

PortfolioSubmission.propTypes = {
  submission: PropTypes.exact(SUBMISSION_PROP_TYPES).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

PortfolioSubmission.defaultProps = {};

export default PortfolioSubmission;
