import React, { useState } from "react";
import PropTypes from "prop-types";
import { CHALLENGE_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import ChallengeCard from "./challenge-card";
import ConfirmButton from "./confirm-button";
import OffensiveContentReportModal from "./offensive-content-report-modal";

const Challenges = (props) => {
  const { challenges, authenticityToken, defaultMedia, currentUser, reportContentPath } = props;

  const [showOffensiveContentReportDialog, setShowOffensiveContentReportDialog] = useState(false);
  const [reportedContent, setReportedContent] = useState(null);

  const footerLink = (challenge) =>
    (
      <>
        {challenge.canEdit ? (
          <div>
            <a className="btn btn-link mr-2" href={challenge.editPath}>
              <i className="fas fa-edit" />
              Edit
            </a>
          </div>
        ) : null}
        {challenge.canDestroy ? (
          <ConfirmButton
            linkLabel="Delete"
            linkIcon="ban"
            confirmIcon="ban"
            confirmLabel="Delete"
            dialogTitle="Warning!"
            dialogText={`Are you sure you want to delete the ${challenge.name} challenge? This cannot be undone.`}
            formId="destroy-challenge"
            orientation="horizontal"
            buttonClassName="btn btn-link p-0 text-danger"
            formClassName="m-0 p-0 ml-auto"
            method="delete"
            action={challenge.destroyPath}
            authenticityToken={authenticityToken}
          />
        ) : null}
        <div>
          <button
            className="btn btn-link mr-2"
            onClick={() => {
              setShowOffensiveContentReportDialog(true);
              setReportedContent(challenge);
            }}
          >
            <i className="fas fa-flag" />
            <span className="smaller">Report Offensive Content</span>
          </button>
        </div>
      </>
    );

  return (
    <>
      <div className="card-deck">
        {challenges.map((challenge) => (
          <ChallengeCard
            key={challenge.id}
            challenge={challenge}
            defaultMedia={defaultMedia}
            setReportedContent={setReportedContent}
            setShowOffensiveContentReportDialog={setShowOffensiveContentReportDialog}
            >
            {{ footerContent: footerLink(challenge) }}
          </ChallengeCard>
        ))}
      </div>

      <OffensiveContentReportModal 
        showModal={showOffensiveContentReportDialog}
        setShowModal={setShowOffensiveContentReportDialog}
        currentUser={currentUser}
        reportedContent={reportedContent}
        reportedContentType="Challenge"
        authenticityToken={authenticityToken}
        reportContentPath={reportContentPath}
      />
    </>
  );
};

Challenges.propTypes = {
  challenges: PropTypes.arrayOf(PropTypes.exact(CHALLENGE_PROP_TYPES))
    .isRequired,
  authenticityToken: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  reportContentPath: PropTypes.string.isRequired,
};

Challenges.defaultProps = {};

export default Challenges;
