import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

const LocalizedDate = (props) => {
  const { value } = props;

  const parsedDateTime = DateTime.fromISO(value);
  const formattedDateTime = parsedDateTime.toLocaleString({
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });

  return <span>{formattedDateTime}</span>;
};

LocalizedDate.propTypes = {
  value: PropTypes.string.isRequired,
};

LocalizedDate.defaultProps = {};

export default LocalizedDate;
