import React from "react";
import PropTypes from "prop-types";
import { GROUP_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import MiniUserCard from "./mini-user-card";

const GroupMembers = (props) => {
  const {
    group: { creator, allUsers },
    authenticityToken,
    currentUser,
    defaultMedia,
  } = props;

  const nonCreatorUsers = () =>
    allUsers.filter((user) => user.id !== creator.id);

  return (
    <div className="card-deck">
      <MiniUserCard user={creator} creator currentUser={currentUser} defaultMedia={defaultMedia} />

      {nonCreatorUsers().map((user) => (
        <MiniUserCard
          key={user.id}
          user={user}
          authenticityToken={authenticityToken}
          currentUser={currentUser}
          defaultMedia={defaultMedia}
        />
      ))}
    </div>
  );
};

GroupMembers.propTypes = {
  group: PropTypes.exact(GROUP_PROP_TYPES).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

GroupMembers.defaultProps = {};

export default GroupMembers;
