import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const ConfirmButton = (props) => {
  const {
    formId,
    linkLabel,
    linkIcon,
    solidIcon,
    method,
    action,
    onConfirmed,
    dialogTitle,
    dialogText,
    confirmIcon,
    confirmLabel,
    cancelLabel,
    authenticityToken,
    orientation,
    buttonClassName,
    formClassName,
  } = props;

  const fontAwesomeClass = solidIcon ? "fas" : "far";

  const id = useMemo(() => `confirm-button-${formId}-${Math.random()}`, []);

  const [showModal, setShowModal] = useState(false);

  return (
    <form
      id={id}
      className={formClassName}
      method="post"
      action={action}
      onSubmit={(e) => {
        if (onConfirmed) {
          e.preventDefault();
          onConfirmed(e);
          setShowModal(false);
        }
      }}
    >
      <input type="hidden" value={method} name="_method" />
      {authenticityToken && (
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
      )}
      <button
        type="button"
        className={`${orientation}-icon-link ${buttonClassName}`}
        onClick={() => setShowModal(true)}
      >
        <i className={`${fontAwesomeClass} fa-${linkIcon}`} />
        {linkLabel}
      </button>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        fade={false}
        centered
      >
        <ModalHeader toggle={() => setShowModal(false)}>
          {dialogTitle}
        </ModalHeader>
        <ModalBody className="d-flex flex-column">{dialogText}</ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            {cancelLabel}
          </button>
          <button type="submit" className="btn btn-primary" form={id}>
            <i className={`fas fa-${confirmIcon || linkIcon}`} />
            {confirmLabel}
          </button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

ConfirmButton.propTypes = {
  formId: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkIcon: PropTypes.string.isRequired,
  solidIcon: PropTypes.bool,
  method: PropTypes.string,
  action: PropTypes.string,
  onConfirmed: PropTypes.func,
  dialogTitle: PropTypes.string,
  dialogText: PropTypes.string.isRequired,
  confirmIcon: PropTypes.string,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  authenticityToken: PropTypes.string,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  buttonClassName: PropTypes.string,
  formClassName: PropTypes.string,
};

ConfirmButton.defaultProps = {
  solidIcon: true,
  method: "get",
  action: null,
  onConfirmed: null,
  dialogTitle: null,
  confirmIcon: null,
  cancelLabel: "Cancel",
  orientation: "vertical",
  authenticityToken: null,
  buttonClassName: "btn btn-link p-0",
  formClassName: "m-0 p-0",
};

export default ConfirmButton;
