import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";
import ValidatableForm from "./validatable-form";

const InviteGroupModal = (props) => {
  const {
    showModal,
    setShowModal,
    authenticityToken,
    challengeInvitationsPath,
  } = props;

  const [sentTo, setSentTo] = useState(null);

  const handleSuccess = (response) => {
    setSentTo(response.name);
  };

  const [submitting, setSubmitting] = useState(false);

  const closeDialog = () => {
    if (submitting) return;
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={closeDialog}
      fade={false}
      centered
      scrollable
    >
      <ModalHeader toggle={closeDialog}>
        Invite Someone to Join Their Own Flock
      </ModalHeader>
      <ModalBody className="d-flex flex-column">
        {sentTo ? (
          <div>
            An invitation to join a flock to this challenge has been sent to{" "}
            {sentTo}!
          </div>
        ) : (
          <ValidatableForm
            action={challengeInvitationsPath}
            authenticityToken={authenticityToken}
            id="challenge-invitation-form"
            onSuccess={handleSuccess}
            setSubmitting={setSubmitting}
          >
            <div className="form-group">
              <label htmlFor="name">Your Friend's Name</label>
              <input
                type="text"
                id="name"
                autoFocus
                className="form-control"
                required
              />
              <div className="invalid-feedback">
                Please provide your friend's name.
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                className="form-control"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid email address.
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" className="form-control" required />
              <div className="invalid-feedback">
                Please provide a message for your invitation.
              </div>
            </div>
          </ValidatableForm>
        )}
      </ModalBody>
      {!sentTo && (
        <ModalFooter>
          <div className="form-group">
            <button
              type="submit"
              form="challenge-invitation-form"
              className="btn btn-primary"
              disabled={submitting}
            >
              {submitting ? (
                <Spinner className="button-spinner" />
              ) : (
                <span>
                  <i className="fas fa-paper-plane" />
                </span>
              )}
              Send Invitation
            </button>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

InviteGroupModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  challengeInvitationsPath: PropTypes.string.isRequired,
};

InviteGroupModal.defaultProps = {};

export default InviteGroupModal;
