import React from "react";
import PropTypes from "prop-types";

const ImageOrPlaceholder = (props) => {
  const { imageUrl, className, placeholder } = props;

  return imageUrl ? (
    <>
      <div
        className={className}
        style={{ backgroundImage: `url('${imageUrl}')` }}
      />
    </>
  ) : (
    <div className={`image-placeholder ${className}`}>
      <div>
        <i className={`image-placeholder-icon fas fa-${placeholder}`} />
      </div>
    </div>
  );
};

ImageOrPlaceholder.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};

ImageOrPlaceholder.defaultProps = {
  imageUrl: null,
  className: null,
};

export default ImageOrPlaceholder;
