// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import LandingPage from "./landing-page";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap-switch-button");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from "jquery";
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import React from "react";
import ReactDOM from "react-dom";
import ModalDialogButton from "./modal-dialog-button";
import ImageField from "./image-field";
import SvgLogo from "./svg-logo";
import ToggleSwitch from "./toggle-switch";
import ChallengeGroups from "./challenge-groups";
import Groups from "./groups";
import ChallengeSubmissions from "./challenge-submissions";
import Challenges from "./challenges";
import ConfirmButton from "./confirm-button";
import ChallengeGroupsSubmissions from "./challenge-groups-submissions";
import DateTimePicker from "./date-time-picker";
import LocalizedDate from "./localized-date";
import Portfolios from "./portfolios";
import PortfolioForm from "./portfolio-form";
import Portfolio from "./portfolio";
import GroupMembers from "./group-members";
import FlockVisibilitySettings from "./flock-visibility-settings";
import ChallengeForm from "./challenge-form";
import MediaViewer from "./media-viewer";
import MediaUploader from "./media-uploader";
import ChallengeCard from "./challenge-card";

const reactComponents = {
  ModalDialogButton,
  ImageField,
  SvgLogo,
  ToggleSwitch,
  ChallengeGroups,
  ChallengeForm,
  Groups,
  ChallengeSubmissions,
  LandingPage,
  Challenges,
  ConfirmButton,
  ChallengeGroupsSubmissions,
  DateTimePicker,
  LocalizedDate,
  Portfolios,
  PortfolioForm,
  Portfolio,
  GroupMembers,
  FlockVisibilitySettings,
  MediaViewer,
  MediaUploader,
  ChallengeCard,
};

(function () {
  "use strict";
  window.addEventListener("load", setupFormValidation, false);
  window.addEventListener("DOMContentLoaded", initializeReactComponents, false);
})();

function setupFormValidation() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.getElementsByClassName("validatable-form");

  // Loop over them and prevent submission
  Array.prototype.filter.call(forms, function (form) {
    form.addEventListener(
      "submit",
      function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
      },
      false
    );
  });
}

function initializeReactComponents() {
  const containers = document.querySelectorAll("div[data-react-component]");
  Array.from(containers).forEach((container) => {
    const properties = Object.keys(container.dataset).reduce((props, key) => {
      const [_, type, name] = key.match(
        /(javascript|boolean|number|date|json|function)*(.*)/
      );
      const keyName = `${name[0].toLowerCase()}${name.slice(1)}`;
      const value = container.dataset[key];

      switch (type) {
        case "javascript":
          props[keyName] = eval(value);
          break;
        case "boolean":
          props[keyName] = value === "true";
          break;
        case "number":
          props[keyName] = parseFloat(value);
          break;
        case "date":
          props[keyName] = new Date(value);
          break;
        case "json":
          props[keyName] = JSON.parse(value);
          break;
        case "function":
          props[keyName] = eval(value);
          break;
        default:
          props[keyName] = value;
      }

      return props;
    }, {});

    const { reactComponent } = properties;

    const Component = reactComponents[reactComponent];
    ReactDOM.render(<Component {...properties} />, container);
  });
}
