import React from "react";
import PropTypes from "prop-types";
import ConfirmButton from "./confirm-button";

const ChallengeGroupUserAffinity = (props) => {
  const {
    groupId,
    isGroupMember,
    userHasAffinity,
    groupName,
    setChallengeGroupUserAffinity,
    challengeState,
  } = props;

  if (!isGroupMember) return null;

  if (userHasAffinity)
    return (
      <div className="alert alert-info small mt-2 mb-0 p-1 text-center">
        <i className="fas fa-star" style={{ fontSize: "1.5em" }} />
        You are competing for this flock!
      </div>
    );

  if (challengeState === "PENDING") {
    return (
      <ConfirmButton
        linkLabel="Compete For This Flock"
        onConfirmed={(_) => {
          setChallengeGroupUserAffinity(groupId);
        }}
        linkIcon="star"
        solidIcon={userHasAffinity}
        confirmIcon="star"
        confirmLabel="Compete For This Flock"
        dialogTitle={`Compete for ${groupName}`}
        dialogText={`Do you want to compete for the ${groupName} flock on this challenge? You can change your mind before the challenge starts.`}
        formId="compete-for-group"
        orientation="horizontal"
        buttonClassName="btn btn-secondary w-100 mt-2 mb-0 btn-sm"
      />
    );
  } else {
    return (
      <div className="not-competing-for-flock small text-center mt-2 mb-0">
        You are not competing for this Flock
      </div>
    );
  }
};

ChallengeGroupUserAffinity.propTypes = {
  groupId: PropTypes.number.isRequired,
  isGroupMember: PropTypes.bool.isRequired,
  userHasAffinity: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  setChallengeGroupUserAffinity: PropTypes.func.isRequired,
  challengeState: PropTypes.string.isRequired,
};

ChallengeGroupUserAffinity.defaultProps = {};

export default ChallengeGroupUserAffinity;
