import React from "react";
import PropTypes from "prop-types";
import { CHALLENGE_PROP_TYPES } from "./constants/prop-types";
import ChallengeCard from "./challenge-card";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PortfolioChallenge = (props) => {
  const { challenge, selected, onSelect, onDeselect, defaultMedia } = props;

  return (
    <ChallengeCard
      challenge={challenge}
      className={classNames("portfolio-item", {
        selected: selected,
      })}
      target="_blank"
      defaultMedia={defaultMedia}
    >
      {{
        beforeContent: selected ? (
          <div className="selected-item">
            <FontAwesomeIcon icon={["fas", "check-circle"]} size="3x" />
          </div>
        ) : null,
        footerContent: selected ? (
          <>
            <input
              type="hidden"
              name="portfolio[challenge_ids][]"
              value={challenge.id}
            />
            <button
              className="btn btn-link mr-2"
              type="button"
              onClick={onDeselect}
            >
              <FontAwesomeIcon icon={["fas", "minus"]} />
              Do Not Include this Challenge
            </button>
          </>
        ) : (
          <button
            className="btn btn-link mr-2"
            type="button"
            onClick={onSelect}
          >
            <FontAwesomeIcon icon={["fas", "plus"]} />
            Include this Challenge
          </button>
        ),
      }}
    </ChallengeCard>
  );
};

PortfolioChallenge.propTypes = {
  challenge: PropTypes.exact(CHALLENGE_PROP_TYPES).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

PortfolioChallenge.defaultProps = {};

export default PortfolioChallenge;
