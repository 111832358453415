import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import ValidatableForm from "./validatable-form";
import MediaUploader from "./media-uploader";

const SubmissionDialog = (props) => {
  const {
    showModal,
    setShowModal,
    authenticityToken,
    challengeSubmissionsPath,
    addSubmission,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    defaultMedia,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const closeDialog = () => {
    if (submitting) return;
    setShowModal(false);
  };

  const handleError = () => {
    setShowErrorMessage(true);
    setSubmitting(false);
  };

  const canSubmit = () => {
    return (!submitting && showErrorMessage);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={closeDialog}
      fade={false}
      centered
      scrollable
    >
      <ModalHeader toggle={closeDialog}>Submit Proof Of Completion</ModalHeader>
      <ModalBody className="d-flex flex-column">
        <ValidatableForm
          action={challengeSubmissionsPath}
          authenticityToken={authenticityToken}
          id="challenge-submission-form"
          onSuccess={addSubmission}
          onError={handleError}
          setSubmitting={setSubmitting}
        >
          {showErrorMessage &&
            <div className="alert alert-warning">Could not save submisison</div>
          }
          <input
            type="hidden"
            name="number"
            value={mediaUploadParams.fields.number}
          />

          <div className="form-group">
            <MediaUploader
              selectLabel="Upload an Image For This Proof"
              id="submission_media"
              mediaUploadSignature={mediaUploadSignature}
              name="submission_media"
              selectedLabel={(mediaType) => `Proof ${mediaType}`}
              environment={environment}
              mediaUploadParams={mediaUploadParams}
              mediaTypeFieldName="submission_media_type"
              defaultMedia={defaultMedia}
            />
            <div className="invalid-feedback">
              Please select an image to upload as proof of completion.
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Tell us what you accomplished!</label>
            <textarea id="description" className="form-control" required />
            <div className="invalid-feedback">
              Please provide a description of your submission.
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              id="quantity"
              className="form-control"
              defaultValue="1"
              min="1"
              required
            />
            <small className="form-text text-muted">
              How many times did you complete the challenge for this submission?
            </small>
            <div className="invalid-feedback">
              Please enter a positive quantity.
            </div>
          </div>
        </ValidatableForm>
      </ModalBody>
      <ModalFooter>
        <div className="form-group">
          <button
            type="submit"
            form="challenge-submission-form"
            className="btn btn-primary"
            disabled={canSubmit()}
          >
            {submitting ? (
              <Spinner className="button-spinner" />
            ) : (
              <span>
                <i className="fas fa-image" />
              </span>
            )}
            Submit Proof!
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SubmissionDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  challengeSubmissionsPath: PropTypes.string.isRequired,
  addSubmission: PropTypes.func.isRequired,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

SubmissionDialog.defaultProps = {};

export default SubmissionDialog;
