import React, { useState } from "react";
import PropTypes from "prop-types";
import { GROUP_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import Group from "./group";
import axios from "axios";
import ConfirmButton from "./confirm-button";
import OffensiveContentReportModal from "./offensive-content-report-modal";

const Groups = (props) => {
  const { groupUserPath, authenticityToken, defaultMedia, currentUser, reportContentPath } = props;

  const [groups, setGroups] = useState(props.groups);
  const [showOffensiveContentReportDialog, setShowOffensiveContentReportDialog] = useState(false);
  const [reportedContent, setReportedContent] = useState(null);

  const leaveGroup = async (group_id) => {
    const response = await axios.delete(
      groupUserPath.replace(/:group_id/, group_id),
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": authenticityToken,
          accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      setGroups(groups.filter((group) => group.id !== group_id));
    } else {
      // TODO: actually handle an error
    }
  };

  const footerLink = (group) => {
    return (
      <>
        {group.canEdit && (
          <div>
            <a className="btn btn-link mr-2" href={group.editPath}>
              <i className="fas fa-edit" />
              Edit
            </a>
          </div>
        )}
        {group.isGroupMember && !group.isGroupCreator && (
          <div>
            <button
              className="btn btn-link"
              onClick={(_) => leaveGroup(group.id)}
            >
              <i className="fas fa-sign-out-alt" />
              Leave Flock
            </button>
          </div>
        )}
        {group.canDestroy ? (
          <ConfirmButton
            linkLabel="Delete"
            linkIcon="ban"
            confirmIcon="ban"
            confirmLabel="Delete"
            dialogTitle="Warning!"
            dialogText={`Are you sure you want to delete the ${group.name} flock? This cannot be undone.`}
            formId="remove-group"
            orientation="horizontal"
            buttonClassName="btn btn-link p-0 text-danger"
            formClassName="m-0 p-0 ml-auto"
            method="delete"
            action={group.destroyPath}
            authenticityToken={authenticityToken}
          />
        ) : null}
        <div>
          <button
            className="btn btn-link mr-2"
            onClick={() => {
              setShowOffensiveContentReportDialog(true);
              setReportedContent(group);
            }}
          >
            <i className="fas fa-flag" />
            <span className="smaller">Report Offensive Content</span>
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-deck">
        {groups.map((group) => (
          <Group key={group.id} group={group} defaultMedia={defaultMedia}>
            {{ footerContent: footerLink(group) }}
          </Group>
        ))}
      </div>

      <OffensiveContentReportModal 
        showModal={showOffensiveContentReportDialog}
        setShowModal={setShowOffensiveContentReportDialog}
        currentUser={currentUser}
        reportedContent={reportedContent}
        reportedContentType="Group"
        authenticityToken={authenticityToken}
        reportContentPath={reportContentPath}
      />
    </>
  );
};

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  groupUserPath: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  setReportedContent: PropTypes.func.isRequired,
  setShowOffensiveContentReportDialog: PropTypes.func.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  reportContentPath: PropTypes.string.isRequired,
};

Groups.defaultProps = {};

export default Groups;
