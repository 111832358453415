import React from "react";
import PropTypes from "prop-types";
import { ownerClass } from "./utils/classes";
import { CHALLENGE_PROP_TYPES } from "./constants/prop-types";
import MediaViewer from "./media-viewer";

const ChallengeCard = (props) => {
  const {
    challenge,
    children: { beforeContent, headerContent, footerContent },
    className,
    target,
    onLinkClick,
    defaultMedia,
  } = props;

  const handleClick = (e) => {
    if (onLinkClick) {
      e.preventDefault();
      onLinkClick(props.group);
    }
  };

  return (
    <div
      className={`card mb-3 mt-3 challenge-card ${className} ${ownerClass(
        challenge.isCreator
      )}`}
    >
      {beforeContent}
      {headerContent && <div className="card-header">{headerContent}</div>}

      <a href={challenge.path} target={target} onClick={handleClick}>
        <MediaViewer
          mediaType={challenge.challengeMediaType}
          media={challenge.challengeMedia}
          purpose="preview"
          className="card-img-top"
          defaultMedia={defaultMedia}
        />
      </a>

      <h5 className="card-header">
        <div className="card-header-label">
          <i className="fas fa-bolt" title="ChallengeCard" />
          {!challenge.public && (
            <i
              className="private-indicator fas fa-lock"
              title="This is a private challenge."
            />
          )}{" "}
          <a href={challenge.path} target={target} onClick={handleClick}>
            {challenge.name}
          </a>
        </div>
      </h5>

      <div className="card-body">
        <table className="table label-value">
          <tbody>
            <tr>
              <th>Participating Flocks:</th>
              <td>{challenge.groups.length}</td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>{challenge.state}</td>
            </tr>
            {challenge.creator && (
              <tr>
                <th>Leader:</th>
                <td>
                  {challenge.isChallengeCreator
                    ? "You"
                    : challenge.creator.fullName}
                </td>
              </tr>
            )}
            <tr>
              <th>Joins After Start:</th>
              <td>
                {challenge.allowJoinsAfterStart ? "Allowed" : "Not Allowed"}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-2 description">
          {challenge.abbreviatedDescription}
        </div>
      </div>

      {footerContent && (
        <div className="card-footer d-flex flex-row">{footerContent}</div>
      )}
    </div>
  );
};

ChallengeCard.propTypes = {
  challenge: PropTypes.exact(CHALLENGE_PROP_TYPES).isRequired,
  children: PropTypes.exact({
    beforeContent: PropTypes.element,
    headerContent: PropTypes.element,
    footerContent: PropTypes.element,
  }),
  className: PropTypes.string,
  target: PropTypes.string,
  onLinkClick: PropTypes.func,
  defaultMedia: PropTypes.object.isRequired,
};

ChallengeCard.defaultProps = {
  className: "",
  children: { beforeContent: null, headerContent: null, footerContent: null },
  target: null,
};

export default ChallengeCard;
