import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

const ConditionalLink = (props) => {
  const { children, href, renderLink, target, handleClick } = props;

  return renderLink ? (
    <a href={href} target={target} onClick={handleClick}>
      {children}
    </a>
  ) : (
    children
  );
};

ConditionalLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  href: PropTypes.string.isRequired,
  renderLink: PropTypes.bool.isRequired,
  target: PropTypes.string,
  handleClick: PropTypes.func,
};
ConditionalLink.defaultProps = {
  target: null,
  handleClick: null,
};

export default ConditionalLink;
