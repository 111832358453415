import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Submission from "./submission";
import { SUBMISSION_PROP_TYPES } from "./constants/prop-types";
import ConfirmButton from "./confirm-button";

const SubmissionsList = (props) => {
  const {
    submissions,
    deleteSubmission,
    challengeIsActive,
    suppressLinks,
    defaultMedia,
    setShowOffensiveContentReportDialog,
    setReportedContent,
    setReportedContentType,
  } = props;

  const footerContent = (submission) => (
    <>
      {!suppressLinks && submission.canDestroy ? (
        <ConfirmButton
          linkLabel="Delete Submission"
          onConfirmed={(_) => deleteSubmission(submission.id)}
          linkIcon="ban"
          confirmIcon="ban"
          confirmLabel="Delete Submission"
          dialogTitle="Warning!"
          dialogText={`Are you sure you want to delete this submission? This action can not be undone.`}
          formId="remove-submission"
          orientation="horizontal"
        />
      ) : null}
      <div>
        <button
          className="btn btn-link mr-2"
          onClick={() => {
            setShowOffensiveContentReportDialog(true);
            setReportedContent(submission);
            setReportedContentType("Submission");
          }}
        >
          <i className="fas fa-flag" />
          <span className="smaller">Report Offensive Content</span>
        </button>
      </div>
    </>
  );

  return submissions.length > 0 ? (
    <>
      <div className="row">
        <div className="col">
          This challenge has {submissions.length}{" "}
          {submissions.length === 1 ? "submission" : "submissions"}.
        </div>
      </div>
      <div className="challenge-submissions">
        <div className="card-deck">
          {submissions.map((submission) => (
            <Submission
              key={submission.id}
              submission={submission}
              suppressLinks={suppressLinks}
              defaultMedia={defaultMedia}
            >
              {{
                footerContent: footerContent(submission),
              }}
            </Submission>
          ))}
        </div>
      </div>
    </>
  ) : (
    <div>
      {challengeIsActive
        ? "There are no proof submissions for this challenge yet."
        : "There were no proof submissions for this challenge."}
    </div>
  );
};

SubmissionsList.propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.exact(SUBMISSION_PROP_TYPES))
    .isRequired,
  deleteSubmission: PropTypes.func,
  challengeIsActive: PropTypes.bool,
  suppressLinks: PropTypes.bool,
  defaultMedia: PropTypes.object.isRequired,
  setReportedContent: PropTypes.func.isRequired,
  setReportedContentType: PropTypes.func.isRequired,
  setShowOffensiveContentReportDialog: PropTypes.func.isRequired,
};

SubmissionsList.defaultProps = {
  deleteSubmission: null,
  challengeIsActive: false,
  suppressLinks: false,
};

export default SubmissionsList;
