import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { useUppy } from "@uppy/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { MEDIA_PROP_TYPES } from "./constants/prop-types";
import snakeCase from "./utils/snake-case";
import MediaViewer from "./media-viewer";
import axios from "axios";

const MediaUploader = (props) => {
  const {
    name,
    id,
    mediaTypeFieldName,
    optional,
    selectLabel,
    accept,
    selectedLabel,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    defaultMedia,
  } = props;

  const fileInput = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [processedMediaData, setProcessedMediaData] = useState(
    props.processedMediaData
  );
  const [mediaType, setMediaType] = useState(props.mediaType);
  const [error, setError] = useState(false);
  const [usingDefault, setUsingDefault] = useState(false);

  useEffect(() => {
    if (props.defaultProcessedMediaData) {
      if (!processedMediaData) {
        setProcessedMediaData(props.defaultProcessedMediaData);
        setMediaType(props.defaultMediaType);
        setUsingDefault(true);
      }
    } else {
      if (usingDefault) {
        setProcessedMediaData(null);
        setMediaType(null);
        setUsingDefault(false);
      }
    }
  }, [props.defaultProcessedMediaData, props.defaultMediaType]);

  const escapeUrlPaths = (mediaData) =>
    Object.keys(mediaData).reduce((acc, key) => {
      acc[key] = mediaData[key].map((mediaItem) => {
        return {
          ...mediaItem,
          sslUrl: new URL(mediaItem.sslUrl).toString(),
          url: new URL(mediaItem.url).toString(),
        };
      });
      return acc;
    }, {});

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getAssemblyStatus = async (assemblySslUrl) => {
    const response = await axios.get(assemblySslUrl);
    updateProgressBar(Object.keys(response.data.results).length);

    if (response.status === 200 && response.data.ok == "ASSEMBLY_COMPLETED") {
      setProcessing(false);
      setProcessedMediaData(escapeUrlPaths(snakeCase(response.data.results)));
    } else {
      await sleep(5000);
      getAssemblyStatus(assemblySslUrl);
    }
  }

  const uppy = useUppy(() => {
    const uppy = new Uppy({
      autoProceed: true,
      debug: true,
      allowMultipleUploadBatches: true,
      restrictions: {
        maxFileSize: 1048576 * 200,
        allowedFileTypes: ["video/*", "image/*"],
      },
    }).use(Transloadit, {
      waitForEncoding: false,
      waitForMetadata: true,
      params: mediaUploadParams,
      fields: { environment, signature: mediaUploadSignature },
    });

    uppy.on("upload", () => {
      setUploading(true);
      setMediaType(uppy.getFiles()[0].type.split("/")[0]);
    });

    uppy.on("progress", setProgress);

    uppy.on("complete", ({ transloadit: [{ results: rawResults }] }) => {
      reset();
      setProcessedMediaData(escapeUrlPaths(snakeCase(rawResults)));
    });

    uppy.on("transloadit:assembly-executing", (assembly) => {
      setProcessing(true);
    });

    uppy.on("transloadit:complete", (assembly) => {
      getAssemblyStatus(assembly.assembly_ssl_url);
    });

    uppy.on("restriction-failed", (file, error) => {
      setError(error.message);
    });

    uppy.on("error", (error) => {
      setError(error.message);
    });

    return uppy;
  });

  const removeMedia = () => {
    uppy.cancelAll();
    setMediaType(null);
    setProcessedMediaData(null);
    setUsingDefault(false);
  };

  const addFiles = (files) => {
    const descriptors = files.map((file) => ({
      source: id,
      name: file.name,
      type: file.type,
      data: file,
    }));

    try {
      uppy.addFiles(descriptors);
    } catch (error) {
      uppy.log(error);
    }
  };

  const onSelect = async (event) => {
    const files = Array.from(event.target.files);
    addFiles(files);

    event.target.value = null;
  };

  const statusLabel = () => {
    if (processing) return `Processing ${mediaType}...`;
    if (progress) return `Uploading ${mediaType}... ${progress}%`;
    if (uploading) return `Uploading ${mediaType}...`;

    return (
      <>
        {selectLabel}
        {optional ? (
          <small className="form-text text-muted">(optional)</small>
        ) : null}
      </>
    );
  };

  const reset = () => {
    setError(null);
    setUploading(false);
    setProgress(0);
  };

  const updateProgressBar = count => {
    const max = (mediaType === 'image' ? 4 : 7);
    const elem = document.getElementById("progressBar");
    if (elem) {
      elem.style.width = (count/max)*100 + "%";
    }
  }

  return (
    <div>
      {processedMediaData ? (
        <>
          <input
            type="hidden"
            name={name}
            id={id}
            value={JSON.stringify(processedMediaData)}
          />
          <input type="hidden" name={mediaTypeFieldName} value={mediaType} />
        </>
      ) : (
        <input
          key="input-file"
          type="file"
          className="input-file"
          ref={fileInput}
          accept={accept}
          onChange={onSelect}
          required={!optional}
        />
      )}
      <div key="media-uploader" className="media-uploader">
        {processedMediaData ? (
          <div>
            <label>{selectedLabel(mediaType)}</label>
            <div>
              <div className={`${mediaType}-preview-container`}>
                <div className="purge-media">
                  <span onClick={removeMedia}>
                    <i className="purge-media-icon fas fa-times-circle" />
                  </span>
                </div>
                <MediaViewer
                  mediaType={mediaType}
                  media={processedMediaData}
                  size="medium"
                  alt={selectedLabel(mediaType)}
                  defaultMedia={defaultMedia}
                />
              </div>
              {processing && (
                <div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h5>Please continue entering your information while your video/image loads</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div id="progressBarDiv">
                        <div id="progressBar"></div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              }
            </div>
          </div>
        ) : (
          <div
            className={`file-selector btn btn-light ${
              uploading || processing ? "disabled" : ""
            }`}
            onClick={(e) => {
              if (uploading) return;

              fileInput.current.click();
              e.preventDefault();
            }}
          >
            <div>
              <div>
                {uploading ? (
                  <Spinner className="resize-spinner" />
                ) : (
                  <FontAwesomeIcon
                    className="select-icon"
                    icon={faCloudUploadAlt}
                  />
                )}
              </div>
              <label htmlFor={id}>{statusLabel()}</label>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={!!error}
        toggle={() => reset}
        fade={false}
        centered
        scrollable
      >
        <ModalHeader toggle={() => reset}>Cannot Upload File</ModalHeader>
        <ModalBody>{error}</ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={reset}>
            Ok
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

MediaUploader.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  mediaTypeFieldName: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  selectLabel: PropTypes.string.isRequired,
  selectedLabel: PropTypes.func.isRequired,
  accept: PropTypes.string,
  processedMediaData: PropTypes.shape(MEDIA_PROP_TYPES),
  mediaType: PropTypes.string,
  defaultProcessedMediaData: PropTypes.shape(MEDIA_PROP_TYPES),
  defaultMediaType: PropTypes.string,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

MediaUploader.defaultProps = {
  optional: false,
  accept: "image/*,video/*",
  processedMediaData: null,
  mediaType: null,
};

export default MediaUploader;
