import React from "react";
import PropTypes from "prop-types";

const SvgLogo = (props) => {
  const { primaryColor, secondaryColor, className, height, width } = props;

  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 1145.45 486.12"
    //   className={className}
    //   height={height}
    //   width={width}
    //   role="img"
    // >
    //
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1180.87 401.17"
      className={className}
    >
      <g>
        <path
          style={{ fill: secondaryColor }}
          d="M41.83,285.23h61.13v14.61H58.24v32h44.72v14.61H58.24v53.42H41.83V285.23z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M120.52,285.23h16.41v99.7h51.61v14.93h-68.02V285.23z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M259.15,283.59c17.71,0,32.46,5.61,44.24,16.82c11.78,11.21,17.67,25.27,17.67,42.18c0,16.9-5.95,30.89-17.83,41.97
		c-11.89,11.08-26.88,16.62-44.98,16.62c-17.27,0-31.67-5.54-43.17-16.62c-11.51-11.08-17.26-24.96-17.26-41.64
		c0-17.12,5.79-31.29,17.38-42.5C226.79,289.19,241.44,283.59,259.15,283.59z M259.81,299.18c-13.12,0-23.9,4.1-32.34,12.31
		c-8.44,8.21-12.67,18.68-12.67,31.43c0,12.36,4.23,22.65,12.71,30.85c8.47,8.21,19.07,12.31,31.81,12.31
		c12.79,0,23.46-4.18,32.01-12.55c8.55-8.37,12.83-18.79,12.83-31.26c0-12.14-4.28-22.36-12.83-30.65
		C282.77,303.32,272.27,299.18,259.81,299.18z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M440.04,373.27v18.05c-12.03,6.56-25.93,9.85-41.68,9.85c-12.8,0-23.66-2.57-32.58-7.71
		c-8.92-5.14-15.97-12.23-21.17-21.25c-5.2-9.03-7.79-18.79-7.79-29.29c0-16.68,5.95-30.72,17.85-42.09
		c11.9-11.38,26.57-17.07,44.02-17.07c12.03,0,25.41,3.15,40.12,9.44v17.64c-13.4-7.66-26.5-11.49-39.3-11.49
		c-13.13,0-24.03,4.14-32.7,12.43c-8.67,8.29-13.01,18.67-13.01,31.14c0,12.58,4.27,22.92,12.8,31.02
		c8.53,8.1,19.45,12.14,32.74,12.14C413.23,386.07,426.8,381.8,440.04,373.27z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M525.05,285.23h20.51l-49.72,54.4l61.95,60.23h-22.4l-58.59-56.62v56.62h-16.41V285.23h16.41v52.43L525.05,285.23z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M570.59,285.23H587v114.63h-16.41V285.23z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M602.67,285.06h95.76v14.61h-39.96v100.19h-16.49V299.67h-39.3V285.06z"
        />
        <path
          style={{ fill: primaryColor }}
          d="M704.17,285.06h95.76v14.61h-39.96v100.19h-16.49V299.67h-39.3V285.06z"
        />
        <path
          style={{ fill: primaryColor }}
          d="M860.77,283.59c17.71,0,32.46,5.61,44.24,16.82c11.78,11.21,17.67,25.27,17.67,42.18
		c0,16.9-5.95,30.89-17.83,41.97c-11.89,11.08-26.88,16.62-44.98,16.62c-17.27,0-31.67-5.54-43.17-16.62
		c-11.51-11.08-17.26-24.96-17.26-41.64c0-17.12,5.79-31.29,17.38-42.5C828.41,289.19,843.06,283.59,860.77,283.59z M861.42,299.18
		c-13.12,0-23.9,4.1-32.34,12.31c-8.44,8.21-12.67,18.68-12.67,31.43c0,12.36,4.23,22.65,12.71,30.85
		c8.47,8.21,19.07,12.31,31.81,12.31c12.79,0,23.46-4.18,32.01-12.55c8.55-8.37,12.83-18.79,12.83-31.26
		c0-12.14-4.28-22.36-12.83-30.65C884.39,303.32,873.88,299.18,861.42,299.18z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M1034.02,285.23h15.34v114.63h-16.49v-89.19l-35.24,44.31h-3.04l-35.65-44.31v89.19h-16.41V285.23h15.54l38.09,47.08
		L1034.02,285.23z"
        />
        <path
          style={{ fill: secondaryColor }}
          d="M1074.07,285.23h64.99v14.61h-48.58v34.95h46.93v14.69h-46.93v35.61h50.13v14.61h-66.55V285.23z"
        />
      </g>
      <g>
        <path
          style={{ fill: primaryColor }}
          d="M244.06,35.33c13.57,13.96,7.31,32.76-0.15,44.31c7.28-11.55,10.62-28.39-1.14-41.84
		c-2.26,4.42-3.91,9.47-4.39,13.51l0.09,0.21c2.53,6.98,5.47,20.54-0.3,35.43c40.91-28,18.83-50.47,12.06-58.08
		C248,30.01,245.86,32.38,244.06,35.33z"
        />
        <path
          style={{ fill: primaryColor }}
          d="M242.98,17.76c0-1.63-1.47-2.95-3.28-2.95c-1.81,0-3.28,1.32-3.28,2.95c0,1.63,1.47,2.95,3.28,2.95
		C241.51,20.71,242.98,19.39,242.98,17.76z"
        />
        <path
          style={{ fill: primaryColor }}
          d="M215,21.98l0.12-0.21c-0.96,11.1,12.21,15.01,20.24,25.06c-4.72-8.12,1.59-12,0.99-20.78
		c-0.21-3.13-1.99-4.78-1.99-7.97c0-2.92,2.35-5.26,5.26-5.26c2.89,0,5.23,2.35,5.26,5.26c0,5.29,4.03,8.45,4.27,8.72
		c-4.03-8.3,2.59-21.63-22.74-26.44c-11.64-1.93-37.21,3.22-41.69,38.2c-3.91,30.62,29.93,66.2,34.17,90.33
		c4.81,27.37-10.59,37.48-10.59,15.31c13.81-31.28-16.15-56.13-57.78-56.04C76.82,88.27,14.02,181.36,0,201.7
		c4.66-1.41,10.44-5.11,12.15-6.95c9.69,6.95,39.67-27.19,39.67-27.19c-7.82,30.38,33.9-10.68,62.8,15.64
		c-6.68,2.2-12.96,3.19-18.02,1.56c3.7,4.6,13.11,5.96,24.48,5.26c0.99,2.77,2.53,4.96,4.36,6.29l0.9,76.25h6.17l0.9-76.25
		c2.35-1.68,4.15-4.75,5.05-8.6c20.39-3.94,46.83-18.44,46.83-18.44c31.67,15.25,66.71,15.31,72.43-2.62
		c6.26-19.55-13.11-34.53-44.79-77.72C184.47,50.07,191.99,20.59,215,21.98z"
        />
      </g>
      <g>
        <path
          style={{ fill: secondaryColor }}
          d="M1159.27,269.71c0,0.14-0.01,0.27-0.04,0.39s-0.05,0.21-0.09,0.29s-0.09,0.14-0.14,0.19s-0.12,0.07-0.2,0.07h-3.52v11.13
		c0,0.06-0.02,0.12-0.06,0.16s-0.1,0.09-0.19,0.13s-0.2,0.07-0.34,0.08s-0.31,0.02-0.52,0.02s-0.38-0.01-0.53-0.02
		s-0.27-0.04-0.36-0.08s-0.16-0.08-0.21-0.13s-0.07-0.1-0.07-0.16v-11.13h-3.42c-0.09,0-0.17-0.02-0.23-0.07s-0.12-0.11-0.16-0.19
		s-0.08-0.18-0.11-0.29s-0.04-0.25-0.04-0.39c0-0.16,0.01-0.3,0.04-0.42s0.06-0.23,0.11-0.32s0.1-0.15,0.16-0.2s0.14-0.07,0.23-0.07
		h9.21c0.08,0,0.14,0.02,0.2,0.07s0.1,0.11,0.14,0.2s0.07,0.19,0.09,0.32S1159.27,269.55,1159.27,269.71z M1176.91,281.78
		c0,0.06-0.02,0.12-0.05,0.16s-0.09,0.09-0.16,0.13s-0.19,0.07-0.33,0.08s-0.32,0.02-0.54,0.02s-0.39-0.01-0.53-0.02
		s-0.24-0.04-0.32-0.08s-0.13-0.08-0.16-0.13s-0.05-0.1-0.05-0.16v-10.83h-0.05l-4.27,10.92c-0.03,0.11-0.15,0.19-0.36,0.23
		s-0.48,0.07-0.81,0.07c-0.27,0-0.49-0.02-0.68-0.07s-0.3-0.12-0.35-0.23l-4.17-10.92h-0.05v10.83c0,0.06-0.02,0.12-0.05,0.16
		s-0.09,0.09-0.16,0.13s-0.18,0.07-0.32,0.08s-0.3,0.02-0.5,0.02c-0.22,0-0.4-0.01-0.54-0.02s-0.25-0.04-0.34-0.08
		s-0.14-0.08-0.18-0.13s-0.05-0.1-0.05-0.16v-12.07c0-0.31,0.09-0.56,0.28-0.74s0.41-0.27,0.68-0.27h1.17c0.3,0,0.55,0.02,0.75,0.06
		s0.38,0.11,0.53,0.21s0.28,0.24,0.39,0.42s0.22,0.41,0.33,0.69l3.35,8.91l3.56-8.91c0.2-0.48,0.44-0.84,0.7-1.05
		s0.64-0.33,1.12-0.33h1.17c0.28,0,0.51,0.09,0.69,0.27s0.27,0.43,0.27,0.74V281.78z"
        />
      </g>
    </svg>
  );
};

SvgLogo.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
SvgLogo.defaultProps = {
  primaryColor: "#ff92a3",
  secondaryColor: "black",
  className: "",
  width: "300",
  height: "100%",
};

export default SvgLogo;
