import React from "react";
import PropTypes from "prop-types";

const Paragraphs = (props) => {
  const { paragraphs, keyPrefix } = props;

  return (
    <>
      {paragraphs.split("\n").map((paragraph, i) => (
        <p key={`${keyPrefix}-${i}`}>{paragraph}</p>
      ))}
    </>
  );
};

Paragraphs.propTypes = {
  paragraphs: PropTypes.string.isRequired,
  keyPrefix: PropTypes.string,
};

Paragraphs.defaultProps = {
  keyPrefix: "p",
};

export default Paragraphs;
