import React from "react";
import PropTypes from "prop-types";
import { ownerClass } from "./utils/classes";
import { SUBMISSION_PROP_TYPES } from "./constants/prop-types";
import ImageOrPlaceholder from "./image-or-placeholder";
import UserAvatar from "./user-avatar";
import ConditionalLink from "./conditional-link";
import MediaViewer from "./media-viewer";

const Submission = (props) => {
  const {
    submission,
    children: { beforeContent, footerContent },
    target,
    className,
    showChallengeLink,
    suppressLinks,
    defaultMedia,
  } = props;

  return (
    <div
      className={`card mb-3 mt-3 submission-card ${className} ${ownerClass(
        submission.belongsToCurrentUser
      )}`}
    >
      {beforeContent}
      <div className="d-flex flex-row align-items-center card-header">
        <UserAvatar
          imageUrl={submission.user.avatarMedia?.thumbnail[0]?.sslUrl}
          initials={submission.user.initials}
          defaultMedia={defaultMedia}
        />
        <div className="submitter">
          <div>
            {submission.user.firstName} {submission.user.lastName}
          </div>
          <div className="group-name">
            <ConditionalLink
              href={submission.group.path}
              target={target}
              renderLink={
                !suppressLinks &&
                (submission.group.public || submission.group.userIsInGroup)
              }
            >
              {!submission.group.public && (
                <i
                  className="private-indicator fas fa-lock"
                  title="This is a private flock."
                />
              )}{" "}
              {submission.group.name}
            </ConditionalLink>
          </div>
        </div>
        <div className="group-icons">
          <ConditionalLink
            href={submission.group.path}
            target={target}
            renderLink={
              !suppressLinks &&
              (submission.group.public || submission.group.userIsInGroup)
            }
          >
            <ImageOrPlaceholder
              imageUrl={(submission.group.groupMedia?.thumbnail || defaultMedia.thumbnail)[0].sslUrl}
              className="submission-group-avatar"
              placeholder="users"
            />
          </ConditionalLink>
        </div>
      </div>

      <MediaViewer
        mediaType={submission.submissionMediaType}
        media={submission.submissionMedia}
        purpose="show"
        className="card-img-top"
        defaultMedia={defaultMedia}
      />

      <div className="card-body">
        <table className="table label-value">
          <tbody>
            {showChallengeLink && (
              <tr>
                <th>Challenge:</th>
                <td>
                  <ConditionalLink
                    href={submission.challenge.path}
                    target={target}
                    renderLink={!suppressLinks}
                  >
                    {submission.challenge.name}
                  </ConditionalLink>
                </td>
              </tr>
            )}
            <tr>
              <th>Quantity:</th>
              <td>{submission.quantity}</td>
            </tr>
          </tbody>
        </table>

        <div className="mt-2 description">{submission.description}</div>
      </div>

      {footerContent && (
        <div className="card-footer d-flex flex-row">{footerContent}</div>
      )}
    </div>
  );
};

Submission.propTypes = {
  submission: PropTypes.exact(SUBMISSION_PROP_TYPES).isRequired,
  children: PropTypes.exact({
    beforeContent: PropTypes.element,
    footerContent: PropTypes.element,
  }),
  target: PropTypes.string,
  className: PropTypes.string,
  showChallengeLink: PropTypes.bool,
  suppressLinks: PropTypes.bool,
  defaultMedia: PropTypes.object.isRequired,
};

Submission.defaultProps = {
  target: null,
  children: {
    beforeContent: null,
    footerContent: null,
  },
  className: "",
  showChallengeLink: false,
  suppressLinks: false,
};

export default Submission;
