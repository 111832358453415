import React from "react";
import PropTypes from "prop-types";
import { GROUP_PROP_TYPES } from "./constants/prop-types";

const MiniGroupCard = (props) => {
  const {
    group: { name, imageUrl, allUsers, public: isPublic, defaultMedia },
  } = props;

  return (
    <div className="mini-group-card owned-by-current-user d-flex p-2 mt-1 mb-1 align-items-center">
      {imageUrl ? (
        <div
          className="group-icon mr-2"
          style={{ backgroundImage: `url('${imageUrl?.thumbnail || defaultMedia.thumbnail[0].sslUrl}')` }}
        />
      ) : (
        <div className="group-icon mr-2">
          <i className="image-placeholder-icon fas fa-users" />
        </div>
      )}

      <div className="group-name">
        <div>
          {!isPublic && (
            <i
              className="private-indicator fas fa-lock"
              title="This is a private flock."
            />
          )}
          {name}
        </div>
        <small>Members: {allUsers.length}</small>
      </div>

      <div className="group-actions">{props.children}</div>
    </div>
  );
};

MiniGroupCard.propTypes = {
  group: PropTypes.exact(GROUP_PROP_TYPES).isRequired,
  defaultMedia: PropTypes.object.isRequired,
};
MiniGroupCard.defaultProps = {};

export default MiniGroupCard;
