import React from "react";
import PropTypes from "prop-types";

const UserAvatar = (props) => {
  const { imageUrl, initials } = props;

  return imageUrl ? (
    <div
      style={{
        backgroundImage: `url("${imageUrl}")`,
      }}
      className="user-icon mr-2"
    />
  ) : (
    <div className="user-icon mr-2">{initials}</div>
  );
};

UserAvatar.propTypes = {
  imageUrl: PropTypes.string,
  initials: PropTypes.string.isRequired,
};

UserAvatar.defaultProps = {
  imageUrl: null,
};

export default UserAvatar;
