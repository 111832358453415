const toSnakeCase = (value) => {
  const [firstWord, ...restOfWords] = value.split("_");

  const capitolizedWords = restOfWords.map((part) => {
    return `${part[0].toUpperCase()}${part.slice(1)}`;
  });

  return [firstWord, ...capitolizedWords].join("");
};

const deepTransformKeys = (item) => {
  if (!item || typeof item !== "object") {
    return item;
  }

  if (Array.isArray(item)) {
    return item.map((element) => deepTransformKeys(element));
  }

  const keys = Array.from(Object.keys(item));

  return keys.reduce((acc, key) => {
    acc[toSnakeCase(key)] = deepTransformKeys(item[key]);

    return acc;
  }, {});
};

export default deepTransformKeys;
