import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageField from "./image-field";
import ValidatableForm from "./validatable-form";
import AsyncSelect from "react-select/async";
import {
  CHALLENGE_PROP_TYPES,
  GROUP_PROP_TYPES,
  PORTFOLIO_PROP_TYPES,
  SUBMISSION_PROP_TYPES,
} from "./constants/prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "reactstrap";
import axios from "axios";
import PortfolioChallenge from "./portfolio-challenge";
import PortfolioGroup from "./portfolio-group";
import PortfolioSubmission from "./portfolio-submission";
import MediaUploader from "./media-uploader";

const PortfolioForm = (props) => {
  const {
    authenticityToken,
    action,
    universitiesPath,
    createdChallenges,
    joinedChallenges,
    createdGroups,
    joinedGroups,
    submissions,
    portfolio,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    defaultMedia,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(0);
  const [selectedChallenges, setSelectedChallenges] = useState(
    portfolio.challenges.map((challenge) => challenge.id)
  );
  const [selectedGroups, setSelectedGroups] = useState(
    portfolio.groups.map((group) => group.id)
  );
  const [selectedSubmissions, setSelectedSubmissions] = useState(
    portfolio.submissions.map((submission) => submission.id)
  );

  const customStyles = {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    control: (provided) => ({
      ...provided,
      borderLeft: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
  };

  const transformUniversity = (university) => ({
    label: university.name,
    value: university.id,
  });

  const searchUniversities = async (search) => {
    const universities = await axios.get(universitiesPath, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
      params: { name: search },
    });

    return universities.data.map(transformUniversity);
  };

  const loadUniversities = (search, callback) => {
    clearTimeout(debounceTimer);

    const timer = setTimeout(async () => {
      const universities = await searchUniversities(search);
      callback(universities);
    }, 500);

    setDebounceTimer(timer);
  };
  return (
    <>
      <ValidatableForm
        action={action}
        authenticityToken={authenticityToken}
        id="portfolio-form"
        onSuccess={(portfolio) => (location.href = portfolio.path)}
        setSubmitting={setSubmitting}
        method={portfolio.id ? "patch" : "post"}
      >
        <h2>Create a Portfolio</h2>

        <input
          type="hidden"
          name="portfolio[number]"
          value={mediaUploadParams.fields.number}
        />

        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={["fas", "search"]} />
              </div>
            </div>
            <AsyncSelect
              name="portfolio[university_id]"
              placeholder="Search for a college or university"
              aria-label="Search for a college or university"
              isSearchable
              loadOptions={loadUniversities}
              styles={customStyles}
              noOptionsMessage={() =>
                "Type to search for a college or university..."
              }
              loadingMessage={() => "Searching..."}
              innerProps={{ required: true }}
              defaultValue={
                portfolio.university &&
                transformUniversity(portfolio.university)
              }
            />
          </div>
          <div className="invalid-feedback">
            Please select the university this portfolio is being created for.
          </div>
        </div>

        <h3>Introduce Yourself</h3>

        <div className="form-group">
          <MediaUploader
            selectedLabel={(mediaType) => `Portfolio Hero ${mediaType}`}
            selectLabel="Upload your Hero Image or Video!"
            name="portfolio[portfolio_media]"
            id="portfolio_portfolio_media"
            mediaTypeFieldName="portfolio[portfolio_media_type]"
            environment={environment}
            processedMediaData={props.portfolio.portfolioMedia}
            mediaType={props.portfolio.portfolioMediaType}
            mediaUploadParams={mediaUploadParams}
            mediaUploadSignature={mediaUploadSignature}
            defaultMedia={defaultMedia}
          />
          <div className="invalid-feedback">
            Please select your hero image to upload.
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="description">Describe what you accomplished</label>
          <textarea
            id="description"
            name="portfolio[description]"
            className="form-control"
            defaultValue={portfolio.description}
            required
          />
          <div className="invalid-feedback">
            Please provide a description of your submission.
          </div>
        </div>

        <h3>Challenges You Created</h3>
        <p>
          Please select the challenges you want included in your portfolio that
          you created.
        </p>

        <div className="card-deck">
          {createdChallenges.map((challenge) => (
            <PortfolioChallenge
              key={`challenge-${challenge.id}`}
              challenge={challenge}
              selected={selectedChallenges.includes(challenge.id)}
              onSelect={() =>
                setSelectedChallenges([...selectedChallenges, challenge.id])
              }
              onDeselect={() =>
                setSelectedChallenges(
                  selectedChallenges.filter(
                    (selectedChallenge) => selectedChallenge !== challenge.id
                  )
                )
              }
              defaultMedia={defaultMedia}
            />
          ))}
        </div>

        <h3>Challenges You Participated In</h3>
        <p>
          Please select the other challenges you participated in that you would
          like to include in your portfolio.
        </p>

        <div className="card-deck">
          {joinedChallenges.map((challenge) => (
            <PortfolioChallenge
              key={`challenge-${challenge.id}`}
              challenge={challenge}
              selected={selectedChallenges.includes(challenge.id)}
              onSelect={() =>
                setSelectedChallenges([...selectedChallenges, challenge.id])
              }
              onDeselect={() =>
                setSelectedChallenges(
                  selectedChallenges.filter(
                    (selectedChallenge) => selectedChallenge !== challenge.id
                  )
                )
              }
              defaultMedia={defaultMedia}
            />
          ))}
        </div>

        <h3>Flocks You Created</h3>
        <p>
          Please select the flocks you want included in your portfolio that you
          created and managed.
        </p>

        <div className="card-deck">
          {createdGroups.map((group) => (
            <PortfolioGroup
              key={`group-${group.id}`}
              group={group}
              selected={selectedGroups.includes(group.id)}
              onSelect={() => setSelectedGroups([...selectedGroups, group.id])}
              onDeselect={() =>
                setSelectedGroups(
                  selectedGroups.filter(
                    (selectedGroup) => selectedGroup !== group.id
                  )
                )
              }
              defaultMedia={defaultMedia}
            />
          ))}
        </div>

        <h3>Flocks You Joined</h3>
        <p>
          Please select the other flocks you joined that you would like to
          include in your portfolio.
        </p>

        <div className="card-deck">
          {joinedGroups.map((group) => (
            <PortfolioGroup
              key={`group-${group.id}`}
              group={group}
              selected={selectedGroups.includes(group.id)}
              onSelect={() => setSelectedGroups([...selectedGroups, group.id])}
              onDeselect={() =>
                setSelectedGroups(
                  selectedGroups.filter(
                    (selectedGroup) => selectedGroup !== group.id
                  )
                )
              }
              defaultMedia={defaultMedia}
            />
          ))}
        </div>

        <h3>Highlight Your Proofs</h3>
        <p>Please select the proofs you want to include in your portfolio.</p>

        <div className="card-deck">
          {submissions.map((submission) => (
            <PortfolioSubmission
              key={`submission-${submission.id}`}
              submission={submission}
              selected={selectedSubmissions.includes(submission.id)}
              onSelect={() =>
                setSelectedSubmissions([...selectedSubmissions, submission.id])
              }
              onDeselect={() =>
                setSelectedSubmissions(
                  selectedSubmissions.filter(
                    (selectedSubmission) => selectedSubmission !== submission.id
                  )
                )
              }
              defaultMedia={defaultMedia}
            />
          ))}
        </div>

        <div className="form-group">
          <button
            type="submit"
            form="portfolio-form"
            className="btn btn-primary"
            disabled={submitting}
          >
            {submitting ? (
              <Spinner className="button-spinner" />
            ) : (
              <span>
                <i className="fas fa-graduation-cap" />
              </span>
            )}
            Save and View This Portfolio
          </button>
        </div>
      </ValidatableForm>
    </>
  );
};

PortfolioForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  universitiesPath: PropTypes.string.isRequired,
  createdChallenges: PropTypes.arrayOf(PropTypes.exact(CHALLENGE_PROP_TYPES))
    .isRequired,
  joinedChallenges: PropTypes.arrayOf(PropTypes.exact(CHALLENGE_PROP_TYPES))
    .isRequired,
  createdGroups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES))
    .isRequired,
  joinedGroups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  submissions: PropTypes.arrayOf(PropTypes.exact(SUBMISSION_PROP_TYPES))
    .isRequired,
  portfolio: PropTypes.exact(PORTFOLIO_PROP_TYPES).isRequired,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

PortfolioForm.defaultProps = {};

export default PortfolioForm;
