import React from "react";
import PropTypes from "prop-types";
import ConditionalLink from "./conditional-link";
import { GROUP_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import { ownerClass } from "./utils/classes";
import MediaViewer from "./media-viewer";

const Group = (props) => {
  const {
    group: {
      isGroupCreator,
      path,
      public: isPublic,
      isGroupMember,
      name,
      allUsers,
      creator,
      abbreviatedDescription,
      groupMedia,
      groupMediaType,
    },
    children: { beforeContent, headerContent, extraBodyContent, footerContent },
    users,
    className,
    target,
    suppressLinks,
    onLinkClick,
    defaultMedia,
  } = props;

  const handleClick = (e) => {
    if (onLinkClick) {
      e.preventDefault();
      onLinkClick(props.group);
    }
  };

  return (
    <div
      className={`card mb-3 mt-3 group-card ${className} ${ownerClass(
        isGroupCreator
      )}`}
    >
      {beforeContent}
      {headerContent && <div className="card-header">{headerContent}</div>}

      <ConditionalLink
        href={path}
        target={target}
        handleClick={handleClick}
        renderLink={!suppressLinks && (isPublic || isGroupMember)}
      >
        <MediaViewer
          mediaType={groupMediaType}
          media={groupMedia}
          purpose="preview"
          className="card-img-top"
          defaultMedia={defaultMedia}
        />
      </ConditionalLink>

      <h5 className="card-header">
        <div className="card-header-label">
          <i className="fas fa-users" title="Flock" />
          {!isPublic && (
            <i
              className="private-indicator fas fa-lock"
              title="This is a private flock."
            />
          )}{" "}
          <ConditionalLink
            href={path}
            target={target}
            handleClick={handleClick}
            renderLink={!suppressLinks && (isPublic || isGroupMember)}
          >
            {name}
          </ConditionalLink>
        </div>
      </h5>

      <div className="card-body d-flex flex-column">
        <table className="table label-value">
          <tbody>
            {/* TODO: consider a different way of handling this conditional? */}
            {users ? (
              <tr
                title={`${users.length} of ${allUsers.length} members are competing for this flock`}
              >
                <th>Competitors:</th>
                <td>{users.length}</td>
              </tr>
            ) : (
              <tr>
                <th>Members:</th>
                <td>{allUsers.length}</td>
              </tr>
            )}
            {creator && (
              <tr>
                <th>Leader:</th>
                <td>{isGroupCreator ? "You" : creator.fullName}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="mt-2 description">{abbreviatedDescription}</div>

        {extraBodyContent && (
          <div className="extra-group-body-content mt-auto">
            {extraBodyContent}
          </div>
        )}
      </div>

      {footerContent && (
        <div className="card-footer d-flex flex-row">{footerContent}</div>
      )}
    </div>
  );
};

Group.propTypes = {
  group: PropTypes.exact(GROUP_PROP_TYPES).isRequired,
  children: PropTypes.exact({
    beforeContent: PropTypes.element,
    headerContent: PropTypes.element,
    extraBodyContent: PropTypes.element,
    footerContent: PropTypes.element,
  }),
  users: PropTypes.arrayOf(PropTypes.exact(USER_PROP_TYPES)),
  className: PropTypes.string,
  target: PropTypes.string,
  suppressLinks: PropTypes.bool,
  onLinkClick: PropTypes.func,
  defaultMedia: PropTypes.object.isRequired,
};

Group.defaultProps = {
  className: "",
  children: {
    beforeContent: null,
    headerContent: null,
    extraBodyContent: null,
    footerContent: null,
  },
  usersWithAffinity: [],
  target: null,
  suppressLinks: false,
  onLinkClick: null,
};

export default Group;
