import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

const ImageField = (props) => {
  const {
    name,
    id,
    purge,
    purgeName,
    selectLabel,
    selectedLabel,
    url,
    optional,
    onChange,
  } = props;

  const fileInput = useRef(null);
  const [previewUrl, setPreviewUrl] = useState(purge ? null : url);
  const [purgeImage, setPurgeImage] = useState(purge);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    setPreviewUrl(url);
    setPurgeImage(false);
  }, [url]);

  const loadFullSizeImageAsDataURL = () =>
    new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const fullSizeImage = document.createElement("img");
        fullSizeImage.onload = (event) => resolve(event.target);
        fullSizeImage.src = event.target.result;
      };

      if (fileInput.current.files.length) {
        reader.readAsDataURL(fileInput.current.files[0]);
        setPurgeImage(false);
      }
    });

  const calculateScale = (fullSizeImage) => {
    const maxDimension = 1110;
    const initialWidth = fullSizeImage.naturalWidth;
    const initialHeight = fullSizeImage.naturalHeight;

    if (initialWidth > initialHeight && initialWidth > maxDimension) {
      return maxDimension / fullSizeImage.naturalWidth;
    } else if (initialHeight > maxDimension) {
      return maxDimension / fullSizeImage.naturalHeight;
    }

    return 1;
  };

  const resizeImageToDataUrl = (fullSizeImage) =>
    new Promise((resolve) => {
      const scale = calculateScale(fullSizeImage);

      const canvas = document.createElement("canvas");
      canvas.width = fullSizeImage.naturalWidth * scale;
      canvas.height = fullSizeImage.naturalHeight * scale;

      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        fullSizeImage,
        0,
        0,
        fullSizeImage.naturalWidth,
        fullSizeImage.naturalHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );

      resolve(canvas.toDataURL("image/jpeg"));
    });

  const onSelect = async () => {
    setResizing(true);
    const fullSizeImage = await loadFullSizeImageAsDataURL();
    const scaledImageDataUrl = await resizeImageToDataUrl(fullSizeImage);
    setPreviewUrl(scaledImageDataUrl);
    setResizing(false);
    onChange(scaledImageDataUrl);
  };

  const removeImage = () => {
    setPreviewUrl(null);
    setPurgeImage(true);
    onChange(null);
  };

  return (
    <div>
      {previewUrl ? (
        <input
          type="hidden"
          name={name}
          id={id}
          value={previewUrl.split(",")[1]}
        />
      ) : (
        <input
          key="input-file"
          type="file"
          className="input-file"
          ref={fileInput}
          accept="image/*"
          onChange={onSelect}
          required={!optional && !previewUrl}
        />
      )}

      <div key="image-uploader" className="image-uploader">
        {previewUrl ? (
          <div>
            <label>{selectedLabel}</label>
            <div className="image-preview-container">
              <div className="purge-image">
                <span onClick={removeImage}>
                  <i className="purge-image-icon fas fa-times-circle" />
                </span>
              </div>
              <img
                src={previewUrl}
                className="image-preview"
                alt="avatar image"
              />
            </div>
          </div>
        ) : (
          <div
            className="file-selector btn btn-light"
            onClick={(e) => {
              if (resizing) return;

              fileInput.current.click();
              e.preventDefault();
            }}
          >
            <div>
              {resizing ? (
                <Spinner className="resize-spinner" />
              ) : (
                <FontAwesomeIcon
                  className="select-icon"
                  icon={faCloudUploadAlt}
                />
              )}
            </div>
            <label htmlFor={id}>
              {selectLabel}
              {optional ? (
                <small className="form-text text-muted">(optional)</small>
              ) : null}
            </label>
          </div>
        )}
        {purgeImage ? (
          <input type="hidden" name={purgeName} value="true" />
        ) : null}
      </div>
    </div>
  );
};

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  purgeName: PropTypes.string.isRequired,
  purge: PropTypes.bool,
  selectLabel: PropTypes.string.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  url: PropTypes.string,
  optional: PropTypes.bool,
  onChange: PropTypes.func,
};
ImageField.defaultProps = {
  optional: false,
  purge: false,
  url: null,
  onChange: () => {},
};

export default ImageField;
