import React from "react";
import PropTypes from "prop-types";
import Group from "./group";
import { GROUP_PROP_TYPES } from "./constants/prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const PortfolioGroup = (props) => {
  const { group, selected, onSelect, onDeselect, defaultMedia } = props;

  return (
    <Group
      key={`group-${group.id}`}
      group={group}
      className={classNames("portfolio-item", {
        selected: selected,
      })}
      target="_blank"
      defaultMedia={defaultMedia}
    >
      {{
        beforeContent: selected ? (
          <div className="selected-item">
            <FontAwesomeIcon icon={["fas", "check-circle"]} size="3x" />
          </div>
        ) : null,
        footerContent: selected ? (
          <>
            <input
              type="hidden"
              name="portfolio[group_ids][]"
              value={group.id}
            />
            <button
              className="btn btn-link mr-2"
              type="button"
              onClick={onDeselect}
            >
              <FontAwesomeIcon icon={["fas", "minus"]} />
              Do Not Include this Flock
            </button>
          </>
        ) : (
          <button
            className="btn btn-link mr-2"
            type="button"
            onClick={onSelect}
          >
            <FontAwesomeIcon icon={["fas", "plus"]} />
            Include this Flock
          </button>
        ),
      }}
    </Group>
  );
};

PortfolioGroup.propTypes = {
  group: PropTypes.exact(GROUP_PROP_TYPES).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

PortfolioGroup.defaultProps = {};

export default PortfolioGroup;
