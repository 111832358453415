import React from "react";
import PropTypes from "prop-types";
import {
  GROUP_SCORE_PROP_TYPES,
  USER_PROP_TYPES,
} from "./constants/prop-types";
import Group from "./group";
import { placeClass } from "./utils/classes";
import ConfirmButton from "./confirm-button";
import ChallengeGroupUserAffinity from "./challenge-group-user-affinity";

const JoinedGroups = (props) => {
  const {
    groupScores,
    challengeState,
    removeGroupFromChallenge,
    setChallengeGroupUserAffinity,
    canSubmitToChallenge,
    defaultMedia,
    setShowOffensiveContentReportDialog,
    setReportedContent,
    setReportedContentType,
  } = props;

  const footerContent = (group) =>
  (
    <>
      {group.canDestroy ? (
        <ConfirmButton
          linkLabel="Remove Flock"
          onConfirmed={(_) => removeGroupFromChallenge(group)}
          linkIcon="ban"
          confirmIcon="ban"
          confirmLabel="Remove Flock"
          dialogTitle="Warning!"
          dialogText={`Are you sure you want to remove the ${group.name} flock from the challenge? Removing this flock will also hide all of the flock's submissions and not count them as a part of the challenge.`}
          formId="remove-group"
          orientation="horizontal"
        />
      ) : null}
      <div>
        <button
          className="btn btn-link mr-2"
          onClick={() => {
            setShowOffensiveContentReportDialog(true);
            setReportedContent(group);
            setReportedContentType("Group");
          }}
        >
          <i className="fas fa-flag" />
          <span className="smaller">Report Offensive Content</span>
        </button>
      </div>
    </>
  );

  return (
    <div>
      {groupScores.length ? (
        <div className="card-deck">
          {groupScores.map(
            ({
              group,
              score,
              placement,
              placementOrdinal,
              canDestroy,
              users,
              userHasAffinity,
            }) => {
              return (
                <Group
                  key={group.id}
                  group={group}
                  className={placeClass(placement)}
                  users={users}
                  defaultMedia={defaultMedia}
                >
                  {{
                    headerContent:
                      challengeState !== "PENDING" ? (
                        <div className="group-score-header d-flex flex-row align-items-center flex-grow-1 justify-content-between">
                          <div className="mr-2">{score} Total</div>

                          {placement && (
                            <div className="d-flex flex-row align-items-center group-award-indicator">
                              <div className="group-award">
                                <i className="fas fa-award" />
                              </div>
                              {placementOrdinal}
                            </div>
                          )}
                        </div>
                      ) : null,
                    extraBodyContent: (
                      <ChallengeGroupUserAffinity
                        groupId={group.id}
                        isGroupMember={group.isGroupMember}
                        userHasAffinity={userHasAffinity}
                        groupName={group.name}
                        setChallengeGroupUserAffinity={
                          setChallengeGroupUserAffinity
                        }
                        challengeState={challengeState}
                      />
                    ),
                    footerContent: footerContent(group),
                  }}
                </Group>
              );
            }
          )}
        </div>
      ) : (
        <p className="mt-3">No flocks have joined this challenge yet.</p>
      )}
    </div>
  );
};

JoinedGroups.propTypes = {
  groupScores: PropTypes.arrayOf(PropTypes.exact(GROUP_SCORE_PROP_TYPES))
    .isRequired,
  challengeState: PropTypes.string.isRequired,
  removeGroupFromChallenge: PropTypes.func.isRequired,
  setChallengeGroupUserAffinity: PropTypes.func.isRequired,
  canSubmitToChallenge: PropTypes.bool.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  setReportedContent: PropTypes.func.isRequired,
  setReportedContentType: PropTypes.func.isRequired,
  setShowOffensiveContentReportDialog: PropTypes.func.isRequired,
};
JoinedGroups.defaultProps = {};

export default JoinedGroups;
