import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GROUP_PROP_TYPES } from "./constants/prop-types";
import MiniGroupCard from "./mini-group-card";
import axios from "axios";

const JoinGroupToChallengeModal = (props) => {
  const {
    showAddGroupDialog,
    setShowAddGroupDialog,
    setShowNewGroupDialog,
    setShowInviteGroupDialog,
    challengeGroupsPath,
    groups,
    authenticityToken,
    onAddChallengeGroup,
    canInviteToChallenge,
    defaultMedia,
  } = props;

  const addGroupToChallenge = async (group) => {
    const formData = new FormData();
    formData.append("group_id", group.id);
    formData.append("authenticity_token", authenticityToken);
    const response = await axios.post(challengeGroupsPath, formData, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
    });

    if (response.status === 201) {
      onAddChallengeGroup(response.data);
    } else {
      // TODO: do something with an error condition.
    }
  };

  return (
    <Modal
      isOpen={showAddGroupDialog}
      toggle={() => setShowAddGroupDialog(false)}
      fade={false}
      centered
      scrollable
    >
      <ModalHeader toggle={() => setShowAddGroupDialog(false)}>
        Add a Flock to this Challenge
      </ModalHeader>
      <ModalBody className="d-flex flex-column">
        <button
          type="button"
          className="btn btn-secondary btn-lg mt-2 mb-2"
          onClick={() => {
            setShowAddGroupDialog(false);
            setShowNewGroupDialog(true);
          }}
        >
          <i className="fas fa-users" />
          Create a New Flock
        </button>

        {canInviteToChallenge && (
          <button
            type="button"
            className="btn btn-secondary btn-lg mt-2 mb-2"
            onClick={() => {
              setShowAddGroupDialog(false);
              setShowInviteGroupDialog(true);
            }}
          >
            <i className="fas fa-paper-plane" />
            Invite Someone to Join Their Own Flock
          </button>
        )}

        {groups.length > 0 && (
          <div>
            <h3>Your Flocks</h3>

            {groups.map((group) => (
              <MiniGroupCard key={group.id} group={group} defaultMedia={defaultMedia}>
                <div className="col-auto">
                  <button
                    className="btn btn-link vertical-icon-link"
                    onClick={(e) => addGroupToChallenge(group)}
                  >
                    <i className="fas fa-plus" />
                    <span>Add</span>
                  </button>
                </div>
              </MiniGroupCard>
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

JoinGroupToChallengeModal.propTypes = {
  showAddGroupDialog: PropTypes.bool.isRequired,
  setShowAddGroupDialog: PropTypes.func.isRequired,
  setShowNewGroupDialog: PropTypes.func.isRequired,
  setShowInviteGroupDialog: PropTypes.func.isRequired,
  challengeGroupsPath: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  onAddChallengeGroup: PropTypes.func.isRequired,
  canInviteToChallenge: PropTypes.bool.isRequired,
  defaultMedia: PropTypes.object.isRequired,
};
JoinGroupToChallengeModal.defaultProps = {};

export default JoinGroupToChallengeModal;
