import React from "react";
import PropTypes from "prop-types";
import { GROUP_USER_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import ConfirmButton from "./confirm-button";

const MiniUserCard = (props) => {
  const { user, creator, authenticityToken, currentUser, defaultMedia } = props;

  const isCurrentUser = (user) => user.id === currentUser.id;

  return (
    <div className="card mb-2 mt-2 p-3 user-card d-flex flex-row align-items-center">
      <div
        className="user-icon mr-2"
        style={{
          backgroundImage: `url('${(user?.avatarMedia || defaultMedia).thumbnail[0].sslUrl}')`,
        }}
      />
      <div className="user-name">
        <div>
          {user.firstName} {user.lastName}
        </div>
        {creator && (
          <small>
            <i className="fas fa-crown" /> Flock Leader
          </small>
        )}
        {/* <div>{user.bio}</div> */}
      </div>

      {!creator && (
        <div className="user-actions">
          {user.canRemoveFromGroup && (
            <ConfirmButton
              formId={`exit-flock-${user.id}`}
              linkIcon={isCurrentUser(user) ? "sign-out-alt" : "ban"}
              linkLabel={isCurrentUser(user) ? "Leave Flock" : "Remove"}
              dialogTitle="Warning!"
              dialogText={
                isCurrentUser(user)
                  ? "Are you sure you want to leave this flock?"
                  : `Are you sure you want to remove ${user.fullName} from this flock?`
              }
              method="delete"
              action={user.groupGroupUserPath}
              confirmIcon={isCurrentUser(user) ? "sign-out-alt" : "ban"}
              confirmLabel={isCurrentUser(user) ? "Leave Flock" : "Remove User"}
              cancelLabel="Cancel"
              authenticityToken={authenticityToken}
            />
          )}
        </div>
      )}
    </div>
  );
};

MiniUserCard.propTypes = {
  user: PropTypes.exact(GROUP_USER_PROP_TYPES).isRequired,
  creator: PropTypes.bool,
  authenticityToken: PropTypes.string,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  defaultMedia: PropTypes.object.isRequired,
};

MiniUserCard.defaultProps = {
  creator: false,
  authenticityToken: null,
};

export default MiniUserCard;
